import DefaultLayout from '@/layouts/DefaultLayout/DefaultLayout'; // 默认布局
import Page from "./Page";
import Home from "../pages/home/Home";
import Mine from "../pages/mine/Mine";
import Battlefield from "../pages/battlefield/Battlefield";
import MiningPool from "../pages/miningPool/MiningPool";

const homeRouter = [
  Page('首页Tab', '', <DefaultLayout/>, [
    Page('首页', 'home', <Home/>),
    Page('产能池', 'miningPool', <MiningPool/>),
    Page('空间战场', 'battlefield', <Battlefield/>),
    Page('我的', 'mine', <Mine />)
  ]),
]

export default homeRouter;