import React, {useEffect, useState} from 'react';
import ButtonLogin from "../buttonLogin/ButtonLogin";
import WalletInfoPopup from "../walletInfoPopup/WalletInfoPopup";
import SwitchNetChain from "@/components/switchNetChain/SwitchNetChain";
import { useWeb3Modal } from '@web3modal/wagmi/react'

const WalletAction = () => {

  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [showSwitchPopup, setShowSwitchPopup] = useState(false);
  const { open } = useWeb3Modal()

  const clickConnect = () => {
      console.log('connect')
      open();
  }

  const clickLogined = () => {
      console.log('logined')
    setShowInfoPopup(true);
  }

  return (
    <>
      <ButtonLogin clickConnect={clickConnect} clickLogined={clickLogined}/>

      <WalletInfoPopup visible={showInfoPopup} onCancel={() => setShowInfoPopup(false)}
        onSwitch={() => {
          setShowInfoPopup(false);
          setShowSwitchPopup(true);
        }}
      />
      <SwitchNetChain visible={showSwitchPopup} onCancel={() => setShowSwitchPopup(false)}/>
    </>
  );
};

export default WalletAction;