import { createContext, useContext, useState } from 'react';

const LocaleContext = createContext();

export function useLocale() {
  return useContext(LocaleContext);
}

export function LocaleProvider({ children }) {
  const [locale, setLocale] = useState(localStorage.getItem('locale') || 'en_US');

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      {children}
    </LocaleContext.Provider>
  );
}
