import React, {useEffect, useState} from 'react';
import styles from './CommonSelectorCoinPopup.module.scss';
import CommonPopup from "components/commonPopup/CommonPopup";
import CommonButton from "components/commonButton/CommonButton";
import CommonSelectorCoin from "./CommonSelectorCoin";
import PropTypes from "prop-types";
import coinCard from "../../assets/images/coin/card.png";
import coinUsdt from "../../assets/images/coin/usdt.png";
import {useTranslation} from "react-i18next";

const CommonSelectorCoinPopup = (props) => {

    const {visible, getVisible, title, confirmText, onConfirm, options, cancel, tips} = props;

    const [selectedCoin, setSelectedCoin] = useState(options[0]);

    let {t} = useTranslation();

    const handleConfirm = () => {
        onConfirm && onConfirm(selectedCoin);
    }

    const onSelectedChange = (item, index) => {
        // console.log(item, index);
        setSelectedCoin(item);
    }


    return (
        <CommonPopup visible={visible} getVisible={getVisible} title={title}>
            <div className={styles.content}>
                <CommonSelectorCoin options={options} onChange={onSelectedChange}></CommonSelectorCoin>
                {
                    tips &&
                    <div className={styles.tips}>
                        {tips}
                    </div>
                }
                <div className={styles.actions}>
                    {
                        cancel &&
                        <CommonButton onClick={getVisible} cancel>{t('common.cancel')}</CommonButton>
                    }
                    <CommonButton onClick={handleConfirm}>{confirmText}</CommonButton>
                </div>
            </div>
        </CommonPopup>
    );
};


CommonSelectorCoinPopup.prototype = {
    visible: PropTypes.bool.isRequired,
    getVisible: PropTypes.func,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
    confirmText: PropTypes.string,
    options: PropTypes.array,
}

CommonSelectorCoinPopup.defaultProps = {
    title: '提前贖回，選擇結算方式',
    confirmText: '前往贖回',
    cancel: false,
    options: [
        {
            coinImg: coinCard,
            title: '用本金結算',
            value: '0'
        },
        {
            coinImg: coinUsdt,
            title: '用USDT結算',
            value: '1'
        }
    ],
}


export default CommonSelectorCoinPopup;