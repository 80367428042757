import Page from "./Page";
import Demo from "../pages/demo/Demo";
import Components from "../pages/demo/Components";
import Styles from "../pages/demo/Styles";

const demoRouter = [
    Page('Demo', 'demo', null, [
        Page('Demo', '', <Demo/>),
        Page('字体', 'styles', <Styles/>),
        Page('组件', 'components', <Components/>),
        // Page('钱包', 'wallet', <DemoWallet/>, [
        //     Page('钱包', '', <DemoWalletHome/>),
        // ]),
    ]),
]

export default demoRouter;