/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-05-06 15:49:45
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2024-03-11 13:12:40
 * @FilePath: \weapp\wepay-h5\src\pages\App\App.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import { Route } from "react-router-dom";
import { useLocale } from './LocaleContext'; // 导入自定义的LocaleProvider
import NotFound from "./pages/common/404/NotFound";
import AnimatedRoutes from "react-animated-router";
import { whiteRoutes, routes } from './router/index'
import { Toaster } from "react-hot-toast";
import UseWalletHook from "./hooks/useWalletHook";
import { useDispatch, useSelector } from "react-redux";
import useUserHooks from "./hooks/useUserHooks";
import { setWalletCoinShow, setHeroLevel } from "./redux/store/app";
import { useEffect, memo, useState } from "react";
import i18n from "./i18n";
import { setDefaultConfig, ConfigProvider } from "antd-mobile";
import zhHK from "antd-mobile/es/locales/zh-HK"; // 引入多语言包 繁体中文
import enUS from "antd-mobile/es/locales/en-US"; // 引入多语言包 英文
import { initUserCards, initUserInfo, setCardCurrentInfo, setCardsAllInfo } from "./redux/store/user";
import Splash from "./pages/splash/Splash";
import nftApi from "service/nftApi";


export default memo(function App() {
  const { locale, setLocale } = useLocale(); // 从自定义的LocaleProvider中获取locale和setLocale
  let dispatch = useDispatch();
  let { initUserData } = useUserHooks();
  console.log('init app data')
  const { isConnected } = UseWalletHook();
  let token = useSelector(state => state.user.token);
  const [antdLocale, setAntdLocale] = useState(enUS); // 语言默认为繁体中文

  useEffect(() => {
    initAppData();
  }, []);

  const initAppData = () => {
    dispatch(setWalletCoinShow({
      showBtc: false,
      // showEth: true,
      // showUsdt: true,
    }))
    if (token) {
      initUserData();
      getLevelList();
    }
  }

  const renderRouter = (routes, parentPath = '') => {
    return routes.map((route, index) => {
      const { path, element, children } = route;
      const routePath = `${parentPath}/${path}`;
      const routeKey = `${index}-${routePath}`;

      return (<Route key={routeKey} path={path} element={element}>
        {children && renderRouter(children, routePath)}
      </Route>);
    });
  };

  const getLevelList = async() => {
    const heroLevel = await nftApi.levelList({ type: 1 })
    .then((data)=>{  
      dispatch(setHeroLevel(data.data))
      console.log(heroLevel.data)
    }).catch(()=>{ 
      
    });
  };



  useEffect(() => {
    console.log('locale', locale)
    if (locale === 'zh_HK') {
      setAntdLocale(zhHK);
    } else {
      setAntdLocale(enUS);
    }
    if (token) {
      getLevelList();
    }
  }, [locale,token]);

  return (<>
    {/*<div style={{background: '#fff'}}>*/}
    {/*    <SafeArea position='top'/>*/}
    {/*</div>*/}
    <ConfigProvider locale={antdLocale}>
      <AnimatedRoutes>
        <Route path="/">
          <Route index element={<Splash />} />
          {/* router example*/}
          {/*<Route path="splash">*/}
          {/*    <Route path="1" element={<Splash1/>}></Route>*/}
          {/*    <Route path="2" element={<Splash2/>}></Route>*/}
          {/*    <Route path="3" element={<Splash3/>}></Route>*/}
          {/*    <Route path="4" element={<Splash4/>}></Route>*/}
          {/*</Route>*/}

          {renderRouter(whiteRoutes)}
          {renderRouter(routes)}
          {
            (isConnected && token && token.length > 0) && renderRouter(routes)
          }
        </Route>
        <Route path="*" element={<NotFound />} />
      </AnimatedRoutes>

      {/*全局使用*/}
      <Toaster toastOptions={{
        success: {
          style: {
            // background: 'green',
          },
        }, error: {
          style: {
            background: '#fba6a6',
          },
        },
      }} />
    </ConfigProvider>

    {/*<div style={{background: '#fff'}}>*/}
    {/*    <SafeArea position='bottom'/>*/}
    {/*</div>*/}
  </>);
})