/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-05 10:31:55
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2024-03-11 13:49:25
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\components\wallet\buttonLogin\ButtonLogin.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, {useEffect, useState} from 'react';
import CommonButton2 from "../../commonButton/CommonButton2";
import {useAccount, useConnect, useSwitchChain} from "wagmi";
import styles from './ButtonLogin.module.scss';
import PropTypes from "prop-types";
import {goerli} from "viem/chains";
import {bsc, bscTestnet} from 'wagmi/chains'
import {formatWalletAddress} from 'utils/utils';
import wagmiConfig from "../../../utils/walletConfig";

const ButtonLogin = (props) => {


    const {isConnected, address,} = useAccount();


    const [isRightNet, setIsRightNet] = useState(false);
    const {chain} = useAccount()
    const {switchChain} =
        useSwitchChain()


    useEffect(() => {
        console.log(chain)
        if (isConnected) {
            checkNetChain();
        }
    }, [chain]);

    const checkNetChain = () => {
        if (chain?.id !== bsc.id) {
            switchChain({chainId: bsc.id})
            setIsRightNet(false);
        } else {
            setIsRightNet(true)
        }
    }

    const clickConnect = () => {
        props.clickConnect && props.clickConnect();
    }

    const clickLogined = () => {
        if (isRightNet) {
            props.clickLogined && props.clickLogined();
        } else {
            checkNetChain();
        }
    }

    if (isConnected) {
        return <CommonButton2 className={`${styles.button} ${isRightNet ? '' : styles.danger}`}
                              onClick={clickLogined}>{formatWalletAddress(address)}</CommonButton2>
    }
    return <CommonButton2 className={styles.button} onClick={clickConnect}>Connect</CommonButton2>;
};

ButtonLogin.prototype = {
    clickConnect: PropTypes.func,
    clickLogined: PropTypes.func,
}


export default ButtonLogin;