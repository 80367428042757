import React, {memo, useEffect, useState} from 'react';
import styles from './Search.module.scss';
import CommonPage from "components/commonPage/page/CommonPage";
import { useTranslation } from 'react-i18next';
import mineApi from 'service/mineApi';
import { useLocation } from 'react-router-dom';
import { toastHide, toastLoading } from "utils/Toast";

export default memo(function Search() {
  const { t } = useTranslation();
  
  // 获取路由参数 等级
  const location = useLocation().state;
  const level = location && location.level;

  // 级别业绩数据
  const [performance, setPerformance] = useState({});

  useEffect(() => {
    if (!level) return;
    toastLoading();
    mineApi.getTeamLevel({
      level: level
    }).then(res => {
      setPerformance(res.data)
    }).finally(() => {
      toastHide();
    })
  }, [level])

  return (
    <CommonPage flex title={t('lbl_check_level_performance')}>
      <div className={styles.teamTop}>
        <div className={styles.avatarBox}>
          <img className={styles.avatar} src={require('@/assets/images/coin/gap.png')} alt='' />
        </div>
        <div className={styles.teamName}>{t('lbl_my_subordinate_level', {level: level})}</div>
      </div>
      <div className={styles.teamInfo}>
        <div className={styles.teamInfoItem}>
          <div className={styles.teamInfoItemLabel}>{t('lbl_my_subordinate_num_level', {level: level})}</div>
          <div className={styles.teamInfoItemValue}>{performance.totalNum}{t('lbl_people')}</div>
        </div>
        <div className={styles.teamInfoItem}>
          <div className={styles.teamInfoItemLabel}>{t('lbl_my_subordinate_consume_level', {level: level})}</div>
          <div className={styles.teamInfoItemValue}>{performance.totalMoney} USDT</div>
        </div>
      </div>
      <div className={styles.tip}>{t('lbl_my_subordinate_consume_level_tip', {level: level})}</div>
    </CommonPage>
  )
})