import React, {useEffect, useState} from 'react';
import styles from "./SwitchNetChain.module.scss";
import CommonPopup from "@/components/commonPopup/CommonPopup";
import {useAccount, useSwitchChain} from "wagmi";
import toast from "react-hot-toast";

const SwitchNetChain = (props) => {

    const {visible, onCancel} = props;

    const [showVisibleInner, setShowVisibleInner] = useState(false);
    const {chain} = useAccount()
    const {chains, isLoading, pendingChainId, switchChain} =
        useSwitchChain({
            onError: (error) => {
                console.log(error)
                toast.error(error.message);
            },
            onSuccess: (chainId) => {
                console.log(chainId)

                onCancel && onCancel();
                setShowVisibleInner(false);
            }
        })

    useEffect(() => {
        if (visible) setShowVisibleInner(true);
    }, [visible]);


    const onSwitchNetwork = (chain) => {
        console.log(chain)
        switchChain?.({chainId:chain.id})
    }

    return (
        <CommonPopup visible={showVisibleInner} getVisible={() => {
            setShowVisibleInner(false);
            onCancel && onCancel();
        }}>
            {/*<div className={styles.current}>Current Net: {chain?.name}</div>*/}
            <div className={styles.switch}>
                {chains.map((x) => (
                    <button
                        disabled={!switchChain || x.id === chain?.id}
                        key={x.id}
                        onClick={() => onSwitchNetwork(x)}
                    >
                        {x.name}
                        {isLoading && pendingChainId === x.id && ' (switching)'}
                    </button>
                ))}
            </div>
        </CommonPopup>
    );
};

export default SwitchNetChain;