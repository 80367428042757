import React, {useEffect, useState} from 'react';
import {useAccount, useAccountEffect, useDisconnect, useSignMessage} from "wagmi";
import {setAddress} from "../redux/store/user";
import {useDispatch, useSelector} from "react-redux";
import useUserHooks from "./useUserHooks";
import {watchAccount} from '@wagmi/core'
import wagmiConfig from "../utils/walletConfig";
import {useDebouncedCallback} from 'use-debounce';

const UseWalletHook = () => {
    const dispatch = useDispatch();
    const userAddress = useSelector(state => state.user.address);
    const userToken = useSelector(state => state.user.token);
    const {userLogOut} = useUserHooks();
    const {disconnect} = useDisconnect();

    //全局监控小狐狸连接状态.
    const {isConnected, address} = useAccount();

    useAccountEffect({
        onConnect(data) {
            console.log('Connected!', data)
            dispatch(setAddress(data.address));
        }, onDisconnect() {
            console.log('Disconnected!')
        },
    })


    useEffect(() => {
        console.log('init watch account')
        const unwatch = watchAccount(wagmiConfig, {
            onChange(account) {
                console.log('Account changed!', account)
                if (account.status == "connected") {
                    if (userToken && (account.address?.toLowerCase() !== userAddress?.toLowerCase())) {
                        console.log('wallet disconnect')
                        disconnect();
                    }
                }
            },
        })

        return () => {
            console.log('unwatch')
            unwatch();
        }
    }, [])


    const debounced = useDebouncedCallback(
        // function
        (value) => {
            // setValue(value);
            console.log('user logout debounce')
            disconnect();
            userLogOut();
        },
        // delay in ms
        300
    );
    useEffect(() => {
        console.log('address change: ', address);
        if (userToken) {
            //是否是用户切换钱包。切换的话需要退出重新登录
            if (address?.toLowerCase() !== userAddress?.toLowerCase()) {
                console.log('user change wallet ')
                console.log(address?.toLowerCase())
                console.log(userAddress?.toLowerCase())
                debounced(1);
            }
        }
    }, [address]);

    return {isConnected};
};

export default UseWalletHook;