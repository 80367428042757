import React, {useEffect, useState} from 'react';

function NotFound() {
    
    return (
        <div>
            Not Found
        </div>
    );
};

export default NotFound;