import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './utils/rem';
import wagmiConfig from "./utils/walletConfig";

//redux https://redux-toolkit.js.org/tutorials/quick-start
import {store, persistor} from './redux/index.js'
import {Provider} from "react-redux";
import {PersistGate} from 'redux-persist/integration/react'
import {LocaleProvider} from './LocaleContext'; // 导入自定义的LocaleProvider
//react router 6
import {
    BrowserRouter
} from "react-router-dom";

// https://github.com/qiqiboy/react-animated-router
import 'react-animated-router/animate.css';
import App from "./App";
import { WagmiProvider} from "wagmi";
import './assets/css/index.scss';
import './assets/css/common.scss';
import './assets/css/custom-theme.scss';
import './assets/css/antd.scss';
import './assets/css/quill.bubble.css';

//i18n
import i18nextInstance from './i18n/index.js';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
const queryClient = new QueryClient()


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <WagmiProvider config={wagmiConfig}>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <LocaleProvider>
                            <App/>
                        </LocaleProvider>
                    </BrowserRouter>
                </QueryClientProvider>
            </WagmiProvider>
        </PersistGate>
    </Provider>
    // </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
