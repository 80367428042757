/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-09-21 17:36:52
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-09-21 18:31:35
 * @FilePath: \MakerProf:\weapp\wepayToB-h5\src\components\icon\menu\IconHelp.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const  Icon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M14.0687 6.63859C14.373 6.63859 14.6473 6.45531 14.7639 6.17406C14.8805 5.89312 14.8159 5.56937 14.6009 5.35406L11.1558 1.90922C11.0417 1.79515 10.857 1.79515 10.743 1.90922L10.0916 2.56062C9.97749 2.67469 9.97749 2.85953 10.0916 2.97344L12.2522 5.1339H1.34874C1.18952 5.1339 1.0603 5.26297 1.0603 5.42234V6.35031C1.0603 6.50953 1.18937 6.63875 1.34874 6.63875H14.0687V6.63859ZM14.6508 9.355H1.93437C1.63015 9.355 1.35577 9.53828 1.23921 9.81953C1.12265 10.1005 1.18718 10.4242 1.40218 10.6395L4.84733 14.0845C4.9614 14.1986 5.14608 14.1986 5.26015 14.0845L5.91155 13.4331C6.02561 13.3191 6.02561 13.1342 5.91155 13.0203L3.75093 10.8597H14.6508C14.812 10.8597 14.9426 10.7289 14.9426 10.5678V9.64703C14.9426 9.48578 14.812 9.355 14.6508 9.355Z" fill="white"/>
        </svg>
    );
};

export default Icon;
