/* 战场 */
import { get, post} from "./request";

const api = {
    /* 稀有度列表 */
    levelList: (data) => (post( '/api/portal/nft/level',data)),
    /* nft市场 */
    nftList: (data) => (post( '/api/portal/nft/product',data)),
    /* 市场轮播 */
    nftBanner: (data) => (post( '/api/portal/nft/carousel',data)),
    /* 我的nft */
    nftMyList: (data) => (post( '/api/portal/nft/my',data)),
    /* 锁价（升级，购买） */
    nftLock: (data) => (post( '/api/portal/nft/lockprice',data)),
    /* 升级 */  
    nftUpdate: (data) => (post( '/api/portal/nft/update',data)),
    /* 购买 */  
    nftBuy: (data) => (post( '/api/portal/nft/buy',data)),
};

export default api;