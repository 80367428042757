import Page from "./Page";
import Splash from "../pages/splash/Splash";

const splashRouter = [
    //注意，有子组件时，父组件不能添加element, 会直接渲染父element,不会渲染子组件
    Page('欢迎页', 'splash', null, [
            Page('欢迎页', '', <Splash/>)
        ]
    ),

]

export default splashRouter;