import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import styles from './CommonTimeRangePicker.module.scss';
import CommonInput from "../commonInput/CommonInput";
import CommonSelector from "../commonSelector/CommonSelector";
import moment from "moment";
import {DatePicker, Toast} from "antd-mobile";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";


const CommonTimeRangePicker = forwardRef((props, ref) => {

    const {onTimeRangeChange, maxDate} = props;
    let {t} = useTranslation();

    const [timeSelector, setTimeSelector] = useState(['week']);
    const [startTime, setStartTime] = useState(moment().subtract(1, 'weeks').format('YYYY-MM-DD'));
    const [endTime, setEndTime] = useState(moment().format('YYYY-MM-DD'));

    const [showDatePicker, setShowDatePicker] = useState(false);
    const [dataPickerDate, setDataPickerDate] = useState(moment().toDate());
    const [datePickerType, setDatePickerType] = useState(1);

    const filterStartTimeOptions = [{label: t('home.timeOnWeek'), value: 'week'}, {
        label: t('home.timeOneMonth'),
        value: 'month'
    }, {label: t('home.timeThreeMonth'), value: 'threeMonth'},]


    useEffect(() => {
        onTimeChange();
    }, []);

    useEffect(() => {
        onTimeChange();
    }, [startTime, endTime]);


    const onTimeChange = () => {
        onTimeRangeChange && onTimeRangeChange(startTime, endTime);
    }

    useImperativeHandle(
        ref,
        () => ({
            resetTimeRange
        }),
    );


    const resetTimeRange = () => {
        setTimeSelector(['week']);
        toggleTimeRange('week');
    }

    /**
     * 選擇器改變
     * @param v
     */
    const filterStartTimeSelectorChange = (v) => {
        // console.log(v);
        setTimeSelector(v);
        toggleTimeRange(v[0])
    }

    /**
     *  顯示日期選擇器
     * @param type 1 start 2 end
     */
    const showDatePickerView = (type = 1) => {
        if (type === 1) {
            setDataPickerDate(moment(startTime).toDate())
        } else if (type === 2) {
            setDataPickerDate(moment(endTime).toDate())
        }
        setDatePickerType(type);
        setShowDatePicker(true);
    }

    const onDatePickerConfirm = (date) => {
        // console.log(date)


        if (datePickerType === 1) {
            //开始时间必须小于结束时间
            if (moment(date).format('YYYY-MM-DD') > endTime) {
                Toast.show('开始时间必须小于结束时间');
                return;
            }

            setStartTime(moment(date).format('YYYY-MM-DD'));
        } else if (datePickerType === 2) {
            //结束时间必须大于开始时间
            if (moment(date).format('YYYY-MM-DD') < startTime) {
                Toast.show('结束时间必须大于开始时间');
                return;
            }

            setEndTime(moment(date).format('YYYY-MM-DD'));
        }
        setTimeSelector(['day'])
        setShowDatePicker(false);
    }


    const toggleTimeRange = (type) => {
        if (type === 'week') {
            setEndTime(moment().format('YYYY-MM-DD'));
            setStartTime(moment().subtract(1, 'week').format('YYYY-MM-DD'))
        } else if (type === 'month') {
            setEndTime(moment().format('YYYY-MM-DD'));
            setStartTime(moment().subtract(1, 'month').format('YYYY-MM-DD'))
        } else if (type === 'threeMonth') {
            setEndTime(moment().format('YYYY-MM-DD'));
            setStartTime(moment().subtract(3, 'month').format('YYYY-MM-DD'))
        }
    }

    return (<div>
        
        <div className={styles.timeRangePicker}>
            <CommonInput textAlign={'center'} value={startTime} layoutType={'filter'}
                         onClick={() => {
                             showDatePickerView(1)
                         }}></CommonInput>
            <div className={styles.center}>
                <div className={styles.centerInner}></div>
            </div>
            <CommonInput border={false} textAlign={'center'} value={endTime} layoutType={'filter'}
                         onClick={() => {
                             showDatePickerView(2)
                         }}></CommonInput>
        </div>


        <DatePicker visible={showDatePicker} value={dataPickerDate} defaultValue={dataPickerDate}
                    max={maxDate ? moment().toDate() : moment().add(2, 'y').toDate()}
                    onConfirm={onDatePickerConfirm}
                    onClose={() => {
                        setShowDatePicker(false)
                    }}
                    onCancel={() => {
                        setShowDatePicker(false)
                    }}
        />
    </div>);
});

CommonTimeRangePicker.prototype = {
    onTimeRangeChange: PropTypes.func,
}

export default CommonTimeRangePicker;