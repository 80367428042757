import splashRouter from "./splash";
import {accountRouter, registerRouter} from "./account";  // 賬戶
import mineRoute from "./mine"; // 我的
import homeRouter from "./home";   // 首页
import walletRouter from "./wallet";    // 钱包
import battlefieldRoute from "./battlefield"; // 空间战场
import miningPoolRoute from "./miningPool"; // 挖矿池 产能池
import nft from "./nft"; // NFT
import setupRoute from "./setup"; // 设置（语言切换）
import demoRouter from "./demo";  // demo
import testRouter from "./test";  // test

const routes = [
    // ...splashRouter,
    ...homeRouter,
    ...accountRouter,
    ...mineRoute,
    ...walletRouter,
    ...battlefieldRoute,
    ...miningPoolRoute,
    ...nft,
    ...setupRoute
]

const whiteRoutes = [
    ...splashRouter,
    ...registerRouter,
    ...demoRouter,
    ...testRouter
]


export {whiteRoutes, routes}

