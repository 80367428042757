/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-09-21 17:36:52
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-12 15:17:06
 * @FilePath: \MakerProf:\weapp\wepayToB-h5\src\components\icon\menu\IconHelp.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const  Icon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
            <g clip-path="url(#clip0_485_10252)">
                <path d="M20.2497 10.5002C20.2497 16.0142 15.7637 20.5 10.2498 20.5C4.73659 20.5 0.250004 16.0142 0.250003 10.5002C0.247805 8.01332 1.17444 5.61531 2.84829 3.77612C2.92182 3.69512 3.01059 3.6294 3.10952 3.58271C3.20845 3.53602 3.31561 3.50929 3.42488 3.50402C3.53415 3.49876 3.64338 3.51508 3.74634 3.55204C3.8493 3.58901 3.94397 3.64589 4.02494 3.71945C4.3666 4.02861 4.39077 4.55527 4.08161 4.89693C2.68713 6.42976 1.91507 8.42794 1.91664 10.5002C1.91664 15.0951 5.65491 18.8334 10.2498 18.8334C14.8456 18.8334 18.583 15.0951 18.583 10.5002C18.583 8.4302 17.8197 6.44607 16.4331 4.9136C16.3595 4.83256 16.3027 4.73782 16.2658 4.63479C16.2289 4.53176 16.2128 4.42247 16.2182 4.31317C16.2236 4.20388 16.2505 4.09672 16.2974 3.99785C16.3443 3.89897 16.4102 3.81031 16.4914 3.73695C16.5725 3.66349 16.6672 3.60673 16.7702 3.56991C16.8733 3.53308 16.9825 3.51692 17.0918 3.52233C17.2011 3.52775 17.3082 3.55464 17.4071 3.60148C17.506 3.64831 17.5946 3.71417 17.668 3.79528C19.333 5.63442 20.2497 8.01604 20.2497 10.5002Z" fill="white"/>
                <path d="M11.04 3.19602L11.04 13.8337C11.04 14.292 10.665 14.667 10.2067 14.667C9.74836 14.667 9.37337 14.292 9.37337 13.8337L9.37337 3.19602L7.5049 5.01215C7.34835 5.16785 7.13654 5.25525 6.91574 5.25525C6.69495 5.25525 6.48314 5.16785 6.32659 5.01215C6.17089 4.8556 6.0835 4.64379 6.0835 4.423C6.0835 4.20221 6.17089 3.99039 6.32659 3.83384L9.5057 0.743892C9.58957 0.660142 9.69038 0.595284 9.80136 0.553663C9.91234 0.512043 10.0309 0.494619 10.1492 0.502562C10.1874 0.499928 10.2258 0.499928 10.264 0.502562C10.3823 0.494595 10.5009 0.512008 10.6119 0.553629C10.723 0.59525 10.8238 0.660121 10.9077 0.743892L14.0868 3.83384C14.2425 3.99039 14.3299 4.20221 14.3299 4.423C14.3299 4.64379 14.2425 4.8556 14.0868 5.01215C13.9302 5.16785 13.7184 5.25524 13.4976 5.25524C13.2768 5.25524 13.065 5.16785 12.9085 5.01215L11.0398 3.19602L11.04 3.19602Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_485_10252">
                <rect width="20" height="20" fill="white" transform="translate(0.25 20.5) rotate(-90)"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default Icon;
