/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-04 11:58:18
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-27 15:14:42
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\service\battlefieldApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/* 战场 */
import { get, post} from "./request";
const Api = {
   info: (params) => (post('/api/portal/nft/battlefield', params)),
   join: (params) => (post('/api/portal/nft/battlefield/join', params)),
   exit: (params) => (post('/api/portal/nft/battlefield/quit', params)),
   history: (params) => (post('/api/portal/nft/battlefield/my', params)),
   rule: (params) => (post('/api/portal/profile/content/detail', {type:3})),

};

export default Api;