/* 我的 */
import {get, post} from "./request";
const mineApi = {
  // 用户总资产
  getAssetsOverview: (params) => (post('/api/portal/assets/overview', params)),
  // 获取代币资产
  getCoinBalance: (data) => (post('/api/portal/wallet/getCoinBalance', data)),
  // 账户余额明细
  balanceHistory: (data) => (post('/api/portal/assets/balance/history', data)),
  // 我的团队
  getMyTeam: (data) => (post('/api/portal/assets/team', data)),
  // 根据币种获取用户资产
  getCoinBalanceByCoin: (data) => (post('/api/portal/assets/getByCoin', data)),
  getExchangeData: (data) => (post('/api/portal/assets/exchange_index', data)),
  // 兑换
  exchange: (data) => (post('/api/portal/assets/exchange', data)),
  // 兑换预计到账数量
  getExchangeAmount: (data) => (post('/api/portal/assets/exchange/amount', data)),
  // 兑换记录
  getExchangeHistory: (data) => (post('/api/portal/assets/exchange/history', data)),
  // 修改团队图标
  updateTeamIcon: (data) => (post('/api/portal/assets/Team/update/avatar', data)),
  // 修改团队昵称
  updateTeamName: (data) => (post('/api/portal/assets/Team/update/name', data)),
  // 查看团队级别业绩
  getTeamLevel: (data) => (post('/api/portal/assets/team/level/performance', data)),
  // 获取我的团队信息
  getMyTeamInfo: (data) => (post('/api/portal/assets/team/info', data)),
  // 我的团队列表
  getMyTeamList: (data) => (post('/api/portal/assets/team/list', data)),
  // 我的团队下级列表
  getMyTeamSubList: (data) => (post('/api/portal/assets/team/junior/list', data)),

};

export default mineApi;