/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-06 09:09:49
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2024-03-10 13:23:07
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\pages\battlefield\Battlefield.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { memo, useEffect, useState, useRef } from 'react';

import { useLocation, useNavigate } from "react-router-dom";
import styles from './Nft.module.scss';
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import CommonPage from "components/commonPage/page/CommonPage";
import CommonButton from "components/commonButton/CommonButton";
import IconExtract from "components/icon/nft/IconExtract";
import IconSwitch from "components/icon/nft/IconSwitch";
import IconCompose from "components/icon/nft/IconCompose";
import gap from "assets/images/coin/gap.png"
import classNames from 'classnames';
import nftApi from "service/nftApi";

export default memo(function Battlefield() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id
  const info = location?.state?.info
  const pathname = location.pathname.includes("shop")?"shop":"nft";
  const [param,setParam] = useState({id});


  
  const rareType = useSelector(state =>  state.app.heroLevel);
  const rare = rareType.find(item=>item.level == info.level) || rareType.find(items=>items.level === "1")

  return (
      <CommonPage flex title={t('nft.skinNFT')} >
        <div className={ classNames(styles.nft,styles[rare.style]) } style={ {"backgroundImage": `url(${info.image}),linear-gradient(180deg, #123556 0%, #071A2C 100%)` } }></div>
        <div className={styles.intro}>
          <div className='flex align-center justify-between text-16'>
            <div className={classNames(styles.tag,styles[rare.style])}>{rare.name}</div>
            {pathname!="shop"? 
            <div className='text-aliceBlue'>{"#"+info.id}</div>:
            <div className='text-aliceBlue'>{t("nft.stock")}：{info.stock}</div>}
          </div>
          <div className='flex align-center justify-between text-20 text-bold'>
            <div>{info.name}</div>
            {/* { pathname==="shop"?"": <div className={styles.coin}><img src={gap}/><span>{info.price}</span> </div> } */}
          </div>
        </div>
        <p className={classNames(styles.intro,styles.border,'text-12 text-aliceBlue')}>
          {info.brief}
        </p>
        { pathname==="nft"&&
          <div className={classNames(styles.btns,styles.intro)}>
            <CommonButton cancel><IconExtract/>{t("nft.extract")}</CommonButton>
            <CommonButton cancel><IconCompose/>{t("nft.compose")}</CommonButton>
            <CommonButton cancel><IconSwitch/>{t("nft.transfer")}</CommonButton>
          </div>
        }
        { pathname==="shop"&&
          <>
            <div className={styles.intro}>
              <div className={styles.coin}> {t("nft.price")}：<img src={gap}/><span>{info.price}</span></div>
            </div>
            <CommonButton onClick={()=>navigate('/shop/material/buy',{state:{id,info}})}>{t("pool.buy")}</CommonButton>
          </>
        }
         
      </CommonPage> 
    );
  })