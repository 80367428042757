/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-14 09:13:01
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2024-02-02 18:01:37
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\service\miningPoolApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/* 产能池 */
import { get, post} from "./request";
const Api = {
   /*签到信息 */
   info: (params) => (post('/api/portal/nft/sign', params)),
   /* 签到 */
   sign: (params) => (post('/api/portal/nft/sign/do', params)),
   /* 记录 */
   history: (params) => (post('/api/portal/nft/sign/log', params)),

   rule: (params) => (post('/api/portal/profile/content/detail', {type:4})),
};

export default Api;