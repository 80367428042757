/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-06 09:09:49
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-08 11:12:03
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\router\battlefield.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Page from "./Page";
import Battlefield from "../pages/battlefield/Battlefield";
import Histroy from "../pages/battlefield/Histroy";
import Rule from "../pages/battlefield/Rule";
import i18next from '../i18n/index.js'

const battlefieldRoute = [
  Page("产能池", 'battlefield', null,[
    // Page('产能池', '', <Battlefield/>),
    Page('活动规则', 'rule', <Rule/>),
    Page('收益明细', 'histroy', <Histroy/>),
])
]
export default battlefieldRoute;