import React, {useEffect, useRef, useState} from 'react';
import styles from "./Filter.module.scss";
import CommonFormItemLayout from "components/commonFormItemLayout/CommonFormItemLayout";
import CommonTimeRangePicker from "components/commonTimeRangePicker/CommonTimeRangePicker";
import CommonPopup from "components/commonPopup/CommonPopup";
import CommonButton from "components/commonButton/CommonButton";
import CommonInput from "components/commonInput/CommonInput";
import {toastError} from "utils/Toast";
import CommonSelector from "components/commonSelector/CommonSelector";
import FilterItemLayout from "@/components/filterItemLayout/FilterItemLayout";
import {filterAllChangeFunc} from "utils/utils";
import {useTranslation} from "react-i18next";
import accountApi from '@/service/accountApi'

const FilterPopup = (props) => {

  const {onCancel, onConfirm} = props;
  let {t} = useTranslation();

  const [visibleInner, setVisibleInner] = useState(false);

  useEffect(() => {
    if (props.visible) {
      setVisibleInner(props.visible);
    }
  }, [props.visible]);

  const timeRangeRef = useRef(null);
  const [timeEnd, setTimeEnd] = useState([]);
  const [inputMin, setInputMin] = useState('');
  const [inputMax, setInputMax] = useState('');

  const handleEndTime = (startTime, endTime) => {
    setTimeEnd([startTime, endTime]);
  }

  const handleInputMin = (v) => {
    setInputMin(v);
  }

  const handleInputMax = (v) => {
    setInputMax(v);
  }

  // 排序类型
  const filterSortOptions = [
    {tt: '时间升序', label: t('lbl_sort_time_asc'), value: 'asc'},
    {tt: '时间降序', label: t('lbl_sort_time_desc'), value: 'desc'},
  ]

  // 收支类型
  const filterInOutOptions = [
    {tt: '收入', label: t('lbl_income'), value: '1'},
    {tt: '支出', label: t('lbl_expenditure'), value: '2'},
  ]

  // 记录类型
  const filterTypeOptions = [
    {tt: '充值', label: t('lbl_recharge'), value: '0'},
    {tt: '提现', label: t('lbl_withdraw'), value: '1'},
    {tt: '购买', label: t('lbl_buy'), value: '11'},
    {tt: '升级', label: t('lbl_upgrade'), value: '12'},
    {tt: '产能池收益', label: t('lbl_pool_income'), value: '13'},
    {tt: '空间战场收益', label: t('lbl_battlefield_income'), value: '14'},
  ]

  // 资产类型
  const [filterAssetsOptions, setFilterAssetsOptions] = useState([]);

  // 收支
  const [selectorInOut, setSelectorInOut] = useState('');
  // 类型
  const [selectorType, setSelectorType] = useState('');
  // 资产
  const [selectorAssets, setSelectorAssets] = useState('');
  // 排序
  const [selectorSort, setSelectorSort] = useState('');

  // 选择收支
  const filterInOUtSelectorChange = (v) => {
    setSelectorInOut(v)
  }

  // 选择类型
  const filterTypeSelectorChange = (v) => {
    setSelectorType(v)
  }
  
  // 选择排序
  const filterSortSelectorChange = (v) => {
    setSelectorSort(v)
  }

  // 选择资产
  const filterAssetsSelectorChange = (v) => {
    console.log('v', v)
    setSelectorAssets(v)
  }

  const handleCancel = () => {
    setVisibleInner(false);
    onCancel && onCancel();
  }

  const handleConfirm = () => {
    if (parseFloat(inputMin) > parseFloat(inputMax)) {
      toastError(t('home.tips6'))
      return;
    }

    setVisibleInner(false);

    //process filter data
    let filterData = {
      timeEnd: {start: timeEnd[0], end: timeEnd[1]},
      inputMin: inputMin,
      inputMax: inputMax,
      selectorInOut: selectorInOut ? selectorInOut.join(',') : '',
      selectorType: selectorType ? selectorType.join(',') : '',
      selectorAssets: selectorAssets ? selectorAssets.join('') : '',
      selectorSort: selectorSort ? selectorSort.join('') : '',
    }

    onConfirm && onConfirm(filterData);
  }

  // 获取用户资产列表
  const getUserAssetsList = () => {
    accountApi.getUserAssetsList().then(res => {
      const data = res.data.map(item => {
        return {
          tt: item.blockCoinName,
          label: item.blockCoinName,
          value: item.coinName
        }
      })
      setFilterAssetsOptions(data)
    })
  }

  const resetFilter = () => {
    timeRangeRef?.current.resetTimeRange();
    setInputMin('')
    setInputMax('')
    setSelectorInOut('');
    setSelectorType('');
    setSelectorAssets('');
    setSelectorSort('');
  }

  useEffect(() => {
    getUserAssetsList();
  }, [])

  return (
    <CommonPopup visible={visibleInner} getVisible={handleCancel}>
      <div className={styles.content}>
        <CommonFormItemLayout tt='变动时间' type2 title={t('lbl_time')}>
          <CommonTimeRangePicker ref={timeRangeRef} onTimeRangeChange={handleEndTime}></CommonTimeRangePicker>
        </CommonFormItemLayout>
        <CommonFormItemLayout tt='数量' type2 title={t('lbl_amount')}>
          <div className={styles.timeRangePicker}>
            <CommonInput textAlign={'center'} layoutType={'filter'}
              tt='最低'
              placeholder={t('lbl_min')}
              type={'number'}
              value={inputMin}
              onChange={handleInputMin}
              onClick={() => {
              }}></CommonInput>
              <div className={styles.center}>
                <div className={styles.centerInner}></div>
              </div>
            <CommonInput textAlign={'center'} layoutType={'filter'}
              tt='最高'
              placeholder={t('lbl_max')}
              type={'number'}
              value={inputMax}
              onChange={handleInputMax}
              onClick={() => {
              }}></CommonInput>
          </div>
        </CommonFormItemLayout>
        <FilterItemLayout tt={'资产'} title={t('lbl_assets')}>
          <CommonSelector columns={2} options={filterAssetsOptions} defaultValue={selectorAssets}
            value={selectorAssets}
            onChange={filterAssetsSelectorChange}></CommonSelector>
        </FilterItemLayout>
        <FilterItemLayout tt={'收支'} title={t('lbl_typeInOut')}>
          <CommonSelector columns={2} options={filterInOutOptions} defaultValue={selectorInOut}
            value={selectorInOut}
            onChange={filterInOUtSelectorChange}></CommonSelector>
        </FilterItemLayout>
        <FilterItemLayout tt={'类型'} title={t('lbl_type')}>
          <CommonSelector multiple columns={2} options={filterTypeOptions} defaultValue={selectorType}
            value={selectorType}
            onChange={filterTypeSelectorChange}></CommonSelector>
        </FilterItemLayout>
        <FilterItemLayout tt={'排序'} title={t('lbl_sort')}>
          <CommonSelector columns={2} options={filterSortOptions} defaultValue={selectorSort}
            value={selectorSort}
            onChange={filterSortSelectorChange}></CommonSelector>
        </FilterItemLayout>
        <div className={styles.actions}>
          <CommonButton cancel onClick={resetFilter}>{t('common.reset')}</CommonButton>
          <CommonButton onClick={handleConfirm}>{t('common.confirm')}</CommonButton>
        </div>
        <div className="h30"></div>
      </div>
    </CommonPopup>
  );
};

export default FilterPopup;