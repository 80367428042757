import Page from "./Page";
import i18next from '../i18n/index.js'

const walletRouter = [
  //注意，有子组件时，父组件不能添加element, 会直接渲染父element,不会渲染子组件
  Page('中心化钱包锁仓页面', 'wallet', null,
    []
  ),
]

export default walletRouter;