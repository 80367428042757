/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-04 11:58:17
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-12 14:55:50
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\pages\demo\Components.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, {useEffect, useState} from 'react';
import CommonPage from "../../components/commonPage/page/CommonPage";
import CommonButton2 from "../../components/commonButton/CommonButton2";
import CommonButton from "../../components/commonButton/CommonButton";
import CommonCheckbox from "../../components/commonCheckbox/CommonCheckbox";
import CommonDropDown from "../../components/commonDropdown/CommonDropDown";
import CommonFormItemLayout from "../../components/commonFormItemLayout/CommonFormItemLayout";
import CommonInput from "../../components/commonInput/CommonInput";
import CommonPwdInput from "../../components/commonPwdInput/commonPwdInput";
import Box from "./Box";
import CommonSelectorCoinPopup from "../../components/commonSelectorCoin/CommonSelectorCoinPopup";
import CommonPopup from "../../components/commonPopup/CommonPopup";

const Components = () => {

    const [showPopup, setShowPopup] = useState(false);
    const [showCoinSelected, setShowCoinSelected] = useState(false);

    useEffect(() => {

    }, []);


    return (
        <CommonPage title={'通用组件'}>
            <div className='body-1'>* 通用组件库位于 `src/components` 目录，可看行查看。</div>

            <Box title={'通用按纽'}>
                <CommonButton>通用按纽</CommonButton>
                <CommonButton cancel>通用按纽 cancel</CommonButton>
                <CommonButton small>通用按纽 small</CommonButton>
            </Box>
            <Box title={'通用按纽2'}>
                <CommonButton2>按纽2</CommonButton2>
                <CommonButton2 small>small</CommonButton2>
            </Box>

            <Box title={'CheckBox'}>
                <CommonCheckbox></CommonCheckbox>
            </Box>

            <Box title={'输入框'}>
                <CommonInput></CommonInput>
            </Box>

            <Box title={'密码输入框'}>
                <CommonPwdInput></CommonPwdInput>
            </Box>

            <Box title={'Form Item 布局 title 和 children'}>
                <CommonFormItemLayout title={'FormItem Title'}>
                    FormItem Child
                </CommonFormItemLayout>
            </Box>

            <Box title={'通用选择框样式'}>
                <CommonDropDown></CommonDropDown>
            </Box>

            <Box title={'通用选择框样式'}>
                <CommonButton onClick={()=>{setShowPopup(true)}}>打开弹窗</CommonButton>
                <CommonPopup visible={showPopup} getVisible={()=>{setShowPopup(false)} }>

                </CommonPopup>
            </Box>
            <Box title={'封装Popup组件，自定义业务内容'}>
                <CommonButton onClick={() => setShowCoinSelected(true)}>显示选择内容</CommonButton>
                <CommonSelectorCoinPopup visible={showCoinSelected}
                                         getVisible={() => {
                                             setShowCoinSelected(false)
                                         }}
                                         onConfirm={() => {
                                             setShowCoinSelected(false)
                                         }}>
                </CommonSelectorCoinPopup>
            </Box>



        </CommonPage>
    );
};

export default Components;