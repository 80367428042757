/* 账户类API */
import {
  get,
  post,
  request
} from "./request";


const accountApi = {
  getNonce: (address) => (post('/api/portal/sso/nonce', {
    'publicAddress': address
  })),
  // 登錄
  login: (data) => (post('/api/portal/sso/login', data)),
  // 登出
  logout: () => (post('/api/portal/sso/logout')),
  // 注冊
  register: (data) => (post('/api/portal/sso/web3/register', data)),
  getAccountInfo: (address) => (post('/api/portal/sso/loadByAddress', {
    publicAddress: address
  })),
  /** 获取验证码（未登录） */
  getVerifyCode: (account) => (post('/api/portal/sso/getAuthCode', {
    account: account
  })),
  /***校验验证码*/
  checkVerifyCode: (account, verifyCode, googleCode) => (post('/api/portal/sso/verifyAuthCode', {
    account: account,
    authCode: verifyCode,
    googleCode: googleCode
  })),

  /** 通过钱包验证密码 */
  checkVerifyCodeByWallet: (address, signature, googleCode) => (post('/api/portal/sso/nonce/verify', {
    publicAddress: address,
    sign: signature,
    googleCode: googleCode,
  })),

  /***修改密码 */
  changePassword: (data) => (post('/api/portal/sso/updatePassword', data)),
  // 获取用户信息
  getUserInfo: (data) => (post('/api/portal/sso/info', data)),


  // 修改昵称
  updateNickname: (data) => (post('/api/portal/profile/update', data)),
  /* google Token验证 */
  googleTokenVerify: (params) => (post('/api/portal/firebase/verify', params)),
  /* firebase 登录（设置登录） */
  firebaseLogin: (params) => (post('/api/portal/firebase/login', params)),
  // 未登录状态下，校验验证码
  checkVerifyCodeNotLogin: (params) => (post('/api/portal/sso/authCode/verify', params)),
  // （未登录情况下）修改登录密码
  updateLoginPasswordNotLogin: (data) => (post('/api/portal/sso/password/update', data)),
  /* 获取验证码 */
  getVerifyCode_: () => (post('/api/portal/sso/vcode')),
  /* 谷歌验证器校验 */
  authGoogleCode: (params) => (post('/api/portal/sso/authCode', params)),
  // 子账户注册
  registerSubAccount: (params) => (post('/api/portal/sso/registerChild', params)),
  // 子账户切换登录
  subAccountLogin: (params) => (post('/api/portal/sso/loginChild', params)),
  // 账户管理
  accountManagement: (params) => (post('/api/portal/assets/account', params)),
  // 用户资产列表
  getUserAssetsList: (params) => (post('/api/portal/assets/list', params)),
  // 更新头像
  updateAvatar: (params) => (post('/api/portal/profile/update/avatar', params)),
  // testCros
  testCros: () => (post('test-cors'))
};

export default accountApi;