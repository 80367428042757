import {sleep} from 'antd-mobile/es/utils/sleep'

let count = 0

export async function mockRequest(page, pageSize) {


    // if (Math.random() > 0.7) {
    //     console.log('mock request failed')
    //     throw new Error('mock request failed')
    // }

    await sleep(300)

    count++

    return {
        total: 60,
        list: [
            'A' + ' ' + page + ' ' + pageSize,
            'B' + ' ' + page + ' ' + pageSize,
            'C' + ' ' + page + ' ' + pageSize,
            'D' + ' ' + page + ' ' + pageSize,
            'E' + ' ' + page + ' ' + pageSize,
            'F' + ' ' + page + ' ' + pageSize,
            'G' + ' ' + page + ' ' + pageSize,
            'H' + ' ' + page + ' ' + pageSize,
            'I' + ' ' + page + ' ' + pageSize,
            'J' + ' ' + page + ' ' + pageSize,
        ]
    }

}