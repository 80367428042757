/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-06 14:53:47
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-08 11:59:37
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\components\icon\miningPool\IconProduce.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const IconHome = (className) => {
    return (
        <svg className={className} width="1em"  height="1em" viewBox="0 0 43 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#233;&#151;&#170;&#231;&#148;&#181; 1">
            <path id="Vector" d="M16.6189 25.1313H9.40749C8.46597 25.1313 7.81649 24.1889 8.15153 23.3097L15.3173 4.50075C15.4142 4.24628 15.5862 4.02727 15.8104 3.87273C16.0346 3.71819 16.3005 3.63542 16.5728 3.63538H28.6648C29.6188 3.63538 30.2692 4.60198 29.9091 5.48572L25.7367 15.7269H33.5914C34.7466 15.7269 35.3629 17.0886 34.601 17.9566L15.3433 39.9001C14.4071 40.967 12.6715 40.0425 13.0347 38.6705L16.6189 25.1313ZM17.4987 6.32288L11.3568 22.4443H18.364C18.5698 22.4443 18.7727 22.4916 18.9573 22.5824C19.1419 22.6733 19.3032 22.8053 19.4287 22.9683C19.5542 23.1314 19.6406 23.321 19.6812 23.5227C19.7218 23.7244 19.7156 23.9327 19.663 24.1316L17.096 33.8299L30.6249 18.4139H23.7386C22.7845 18.4139 22.1341 17.4473 22.4943 16.5636L26.6666 6.32243H17.4987V6.32288Z" fill="currentColor"/>
            </g>
        </svg>
    );
};

export default IconHome;
