import React, {useEffect, useState} from 'react';
import styles from './CommonPageTips.module.scss';
import {Image} from "antd-mobile";

import imageError from "../../assets/images/common/tips-error.png";
import imageOther1 from "../../assets/images/common/tips-other1.png";
import { ReactComponent as ImageTips } from '@icon/tips-success.svg';

import CommonButton from "../commonButton/CommonButton";
import PropTypes from "prop-types";

const CommonPageTips = (props) => {
  const {type, title, tips, textConfirm, textCancel, onConfirm, onCancel, countDownTime} = props;
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [countDown, setCountDown] = useState(countDownTime);

  useEffect(() => {
    if (countDown > 0) {
      setButtonDisabled(true);
    }

    // 创建定时器
    const interval = setInterval(() => {
      // 每次定时器触发时执行的操作
      console.log('countDown', countDown)
      if (countDown === 0) {
        return;
      }
      if (countDown <= 1) {
        setCountDown(0);
        setButtonDisabled(false);
      } else {
        setCountDown(countDown - 1);
      }
    }, 1000);

    // 返回一个函数，用于在组件卸载时清除定时器
    return () => clearInterval(interval);
  }, [countDown]); // 第二个参数是依赖数组，这里传入空数组表示只在组件挂载和卸载时执行一次

  const imageTips = () => {
    switch (type) {
      case 'default':
        return <ImageTips className={styles.image} />;
      case 'error':
        return imageError;
      case 'other1':
        return imageOther1;
      default:
        return imageTips;
    }
  }

  return (
    <div className={styles.body}>
      {imageTips()}
      <div className={styles.tips1}>
        {title}
      </div>
      <div className={styles.tips2}>
        {tips}
      </div>
      {
        textConfirm &&
        <CommonButton className={styles.btn1} onClick={onConfirm} disabled={buttonDisabled}>
          {textConfirm} {countDown > 0 && `(${countDown}s)`}
        </CommonButton>
      }
      {
        textCancel &&
        <CommonButton className={styles.btn2} cancel onClick={onCancel} disabled={buttonDisabled}>
          {textCancel}
        </CommonButton>
      }
    </div>
  );
};

CommonPageTips.prototype = {
  type: PropTypes.oneOf(['default', 'error', 'other1']).isRequired,
  title: PropTypes.string.isRequired,
  tips: PropTypes.string.isRequired,
  textConfirm: PropTypes.string.isRequired,
  textCancel: PropTypes.string.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  countDownTime: PropTypes.number,
}

CommonPageTips.defaultProps = {
  type: 'default',
  title: '帳戶注册成功',
  tips: '您的帳戶已成功注册，現在讓我們享受该软件功能！',
  textConfirm: '确认',
  textCancel: '取消',
  countDownTime: 0,
}

export default CommonPageTips;