/**
 * 公共确认弹窗组件
 * @param {String} title 弹窗标题
 * @param {String} content 弹窗内容
 * @param {Function} onConfirm 确认回调
 * @param {Function} onCancel 取消回调
 * @param {String} type 弹窗类型 默认 alert
 */
import { Dialog } from 'antd-mobile';
import './CommonDialog.module.scss'

const CommonDialog = (props) => {
  const { title, content, onConfirm, onCancel, type="alert", okText, cancelText } = props;
  const dialogType = type === "alert" ? Dialog.alert : Dialog.show;
  dialogType({
    title: title,
    content: content,
    className: 'common-dialog',
    confirmText: okText || "确认",
    cancelText: cancelText || "取消",
    actions: dialogType === Dialog.show ? [
      [
        {
          key: 'cancel',
          text: cancelText || "取消",
          danger: true,
          onClick: () => {
            // 关闭弹窗
            Dialog.clear();
            onCancel && onCancel();
          }
        },
        {
          key: 'confirm',
          text: okText || "确认",
          onClick: () => {
            // 关闭弹窗
            Dialog.clear();
            onConfirm && onConfirm();
          }
        }
      ]
    ] : null,
    ...props
  });
}

export default CommonDialog
