/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-09-15 10:48:22
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2024-01-02 10:34:11
 * @FilePath: \MakerProf:\weapp\wepayToB-h5\src\components\commonNavBar\CommonNavBar.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import {NavBar} from "antd-mobile";
import {useNavigate} from "react-router-dom";
import './CommonNavBar.scss'
import { ReactComponent as ArrowLeft } from '@icon/arrow_left.svg';

const CommonNavBar = (props) => {
  const {children, className, dark, ...rest} = props;
  let navigate = useNavigate();
  const backClick = () => {
    if(rest.onBack) {
      rest.onBack()
    } else {
      // 如果直接在地址栏输入二级页面或三级页面的 URL，没有历史记录，返回首页
      if(window.history.length <= 1) {
        navigate('/');
      } else {
        navigate(-1);
      }
    }
  }
  const leftBack = () => {
    return  (
      <ArrowLeft />
    )
  }
  return (
    <NavBar
      onBack={ backClick} 
      style={{"--height":"50px"}}
      backArrow={ leftBack() } 
      className={`navbar ${className} ${dark ? 'navbarDark' :''}`}
      left={ rest.back}
      {...rest}
      
    >
      {children}
    </NavBar>
  );  
};

export default CommonNavBar;