import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useAccount, useSignMessage} from "wagmi";
import accountApi from "@/service/accountApi";

const UseWalletSignHook = () => {

    const {address} = useAccount();
    const {signMessageAsync} = useSignMessage();

    // 获取钱包签名
    const getWalletSign = async () => {
        return new Promise((resolve, reject) => {
            accountApi.getNonce(address)
                .then(res => {
                    if (res.status === 200) {
                        return signMessageAsync({message: res.data.nonce})
                    }
                })
                .then(data => {
                    resolve(data);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    return {getWalletSign};
};

export default UseWalletSignHook;