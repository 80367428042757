import React, {useEffect, useState, memo} from 'react';
import styles from './Splash.module.scss';

import logo from 'assets/images/logo.png';
import Splash1 from 'assets/images/splash/1.png';
import {useNavigate} from "react-router-dom";
import CommonButton from "@/components/commonButton/CommonButton";
import accountApi from "@/service/accountApi";
import {useDispatch, useSelector} from "react-redux";
import {useAccount, useSignMessage} from "wagmi";
import {initUserCards, initUserInfo, setToken} from "@/redux/store/user";
import {useTranslation} from "react-i18next";
import useUserHooks from "@/hooks/useUserHooks";
import {ReactComponent as BtnSwitchLang} from '@icon/lan.svg';
import LangPopup from "@/components/langPopup/LangPopup";
import {toastError, toastHide, toastLoading, toastSuccess} from 'utils/Toast';
import axios from 'axios';
import {useWeb3Modal} from '@web3modal/wagmi/react'
import {useWeb3ModalState} from "@web3modal/scaffold-react";
import {useDebounce} from "use-debounce";

export default memo(function Splash() {

    let navigate = useNavigate();
    const [langVisible, setLangVisible] = useState(false);

    let dispatch = useDispatch();
    let {t} = useTranslation();

    const {address, isConnected} = useAccount()
    const {signMessageAsync} = useSignMessage();
    let {initUserData} = useUserHooks();
    const {open} = useWeb3Modal()
    const [allowUserLogin, setAllowUserLogin] = useState(false);

    const clickConnect = () => {
        console.log('a')
        console.log('clickConnect',allowUserLogin,isConnected)
        if (!allowUserLogin) return

        //连接钱包
        if (isConnected) {
            walletConnectSuccess();
        } else {
            open();
        }
    }

    // useEffect(()=>{
    //     console.log('isConnected: ',isConnected)
    // },[isConnected])

    useEffect(() => {
        setTimeout(() => {
            setAllowUserLogin(true);
        }, 300);
    }, [])

    const walletConnectSuccess = () => {
        //检测用户是否注册过钱包。 注册过进入首页，没有注册过进入注册页面
        accountApi.getNonce(address).then(res => {
            if (res.data.status === 1) {
                //用户存在。 调用登录接口
                signMessageAsync({message: res.data.nonce}).then(res => {
                    return accountApi.login({
                        'identify': address,
                        'secret': res,
                        type: 1
                    });
                }).then(res => {
                    dispatch(setToken(res.data.token));
                    initUserData();
                    setTimeout(() => {
                        // toastHide()
                        navigate('/home');
                    }, 30)
                }).catch(error => {
                    console.log(error)
                    toastError(error.message);
                })
            } else {
                //用户不存在，进入注册页面
                navigate('/register');
            }
        }).catch(error => {
            console.log(error)
        });
        // console.log('walletConnectSuccess');
        // dispatch(setToken('hello world token'));
        //
        // setTimeout(() => {
        //     navigate('/home');
        // }, 30)
    }

    const walletConnectError = () => {

    }

    const testCros = () => {
        // 括号里的是请求的地址，可替换成自己的请求地址
        axios.post('https://apinew.newworld.vip/test-cors').then(res => {
            alert('测试1成功 ==> 请求URL: https://apinew.newworld.vip/test-cors ==> 返回结果：' + JSON.stringify(res));
            console.log(res)
        }).catch(error => {
            alert('测试1错误 ==> 请求URL: https://apinew.newworld.vip/test-cors ==> 返回结果：' + JSON.stringify(error));
        })
    }

    return (<div className={styles.splash}>
        <div className={styles.header}>
            <img src={logo} alt='logo'/>
            {/* 切换语言 */}
            <BtnSwitchLang className={styles.btnLan} onClick={() => setLangVisible(true)}/>
        </div>
        <div className={styles.body}>
            <div className={styles.main}>
                <img src={Splash1} className={styles.splashImg} alt='splash' onClick={testCros}/>
                <div className={styles.title}>
                    <div className="caption-1">{t('splash.connectWallet')}</div>
                    <div className='body-1'>{t('splash.connectWalletDesc')}</div>
                </div>
            </div>

            <div className={styles.action}>
                <CommonButton
                    disabled={!allowUserLogin}
                    onClick={() => {
                        clickConnect()
                    }}>
                    {
                        isConnected && allowUserLogin ? t('common.login') : t('common.linkWallet')
                    }
                </CommonButton>
                <div className="h30"></div>
            </div>
        </div>

        {/* 切换语言底部弹出层 */}
        <LangPopup visible={langVisible} getVisible={setLangVisible}/>
    </div>);
});