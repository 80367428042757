/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-08 09:16:45
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-27 15:41:54
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\pages\miningPool\Rule.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { memo, useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CommonPage from "components/commonPage/page/CommonPage";
import CommonNavBar from 'components/commonNavBar/CommonNavBar';
import CommonPageScrollList from "@/components/commonPage/ScrollList/CommonPageScrollList";
import CommonPopup from 'components/commonPopup/CommonPopup';
import FilterItemLayout from "components/filterItemLayout/FilterItemLayout";
import CommonInput from "components/commonInput/CommonInput";
import CommonSelector from "components/commonSelector/CommonSelector";
import CommonTimeRangePicker from "components/commonTimeRangePicker/CommonTimeRangePicker";
import CommonButton from "components/commonButton/CommonButton";
import { Form } from "antd-mobile";
import { ReactComponent as IconFilter } from "assets/images/icon/Filter.svg";
import styles from './Histroy.module.scss';
import battlefieldApi from "service/battlefieldApi";
import moment from 'moment'


export default memo(function MiningPool() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [filterVisible, setFilterVisible] = useState(false);
  const [param, setParam] = useState({name:"",minround:"",maxround:"",minearn:"",maxearn:"",status:[],order:[]});
  const changeParams = (value, key) => { setParam(prevState => { 
    prevState[key] = value; 
    return { ...prevState } }) }
  const timeRangeRef = useRef(null);
  const [form] = Form.useForm();
  const refList = useRef(null);
  const sortType = [
    { label: t("lbl_sort_time_asc"), value: "1",filter:{orderBy:"update_time",orderType:"ASC"} },
    { label: t("lbl_sort_time_desc"), value: "2",filter:{orderBy:"update_time",orderType:"DESC"} },
    { label: t("lbl_sort_score_asc"), value: "3",filter:{orderBy:"earn",orderType:"ASC"} },
    { label: t("lbl_sort_score_desc"), value: "4",filter:{orderBy:"earn",orderType:"DESC"} },
  ];

  const statusList = [
    { label: t("pool.progress"), value: "1" },
    { label: t("pool.successExit"), value: "2" },
    { label: t("pool.failExit"), value: "3" },
    { label: t("pool.activeExit"), value: "4" },
  ];

  useEffect(() => {
  }, [])

  const getApiData = (page) => {
    const sortIteme = sortType.find(item => item.value == param.order[0])||{}

    let params = { ...param, ...sortIteme.filter ,status: param.status?.join(',')||""  }
    
    return battlefieldApi.history({
      pageNo: page,
      pageSize: 20,
      ...params
    });
  }

  const renderListItem = (item, index) => {
    const status = statusList.find( items=> items.value == item.status )||status[0]
    return (
      <div className={styles.listItem}>
        <div className={styles.listContent}>
          <div className='flex justify-between text-bold'>
            <span className='text-orange'>{item.nft?.name}</span>
            <span className='text-aliceBlue'> {status.label }</span>
          </div>
          <div className='flex gap24 text-bold'>
            <span>{t("pool.levelNum")} {item.round}</span>
            <span>{t("pool.contributionScore")} {item.earn}</span>
          </div>
        </div>
        <div className={styles.listFooter}>
          <span className='text-aliceBlue'>{ moment(item.createdTime).format('HH:mm:ss DD-MM-YYYY') }</span>
        </div>
      </div>
    )
  }

  const search = async () => {
    console.log(param)
    refList?.current.refreshApiData();
    setFilterVisible(false);
  }

  const reset = async () => {
    setParam({name:"",minround:"",maxround:"",minearn:"",maxearn:"",status:[],order:[]})
  }

  return (
    <CommonPage flex header={
        <CommonNavBar right={<IconFilter className={styles.filterBtn} onClick={() => setFilterVisible(true)} />}>
          {t('pool.record')}
        </CommonNavBar>
      }>
      <CommonPageScrollList
        ref={refList}
        renderListItem={renderListItem}
        getApiData={getApiData}>
      </CommonPageScrollList>
      <CommonPopup visible={filterVisible} getVisible={setFilterVisible}
        footer={<div className={styles.action}>
          <CommonButton cancel onClick={reset}>{t('common.reset')}</CommonButton>
          <CommonButton shadow onClick={search}>{t('common.confirm')}</CommonButton>
        </div>}>

        <FilterItemLayout title={t('pool.time')}>
          <CommonTimeRangePicker ref={timeRangeRef} onTimeRangeChange={(start,end) => { 
              changeParams(start, "mintime") 
              changeParams(end, "maxtime") 
            }}></CommonTimeRangePicker>
        </FilterItemLayout>

        <FilterItemLayout title={t('nft.name')}>
          <CommonInput type='text' placeholder={t("lbl_input_nft_name")} value={ param.name }  onChange={val => { changeParams(val, "name") }} />
        </FilterItemLayout>

        <FilterItemLayout title={t('pool.levelNum')}>
          <div className='flex align-center gap4'>
            <CommonInput type='text' placeholder={t("lbl_min")} value={ param.minround } onChange={val => { changeParams(val, "minround") }} />
            <div>-</div>
            <CommonInput type='text' placeholder={t("lbl_max")} value={ param.maxround }  onChange={val => { changeParams(val, "maxround") }} />
          </div>
        </FilterItemLayout>
 
        <FilterItemLayout title={t('pool.contributionScore')}>
          <div className='flex align-center gap4'>
            <CommonInput type='text' placeholder={t("lbl_min")}  value={ param.minearn } onChange={val => { changeParams(val, "minearn") }} />
            <div>-</div>
            <CommonInput type='text' placeholder={t("lbl_max")}  value={ param.maxearn } onChange={val => { changeParams(val, "maxearn") }} />
          </div>
        </FilterItemLayout>

        <FilterItemLayout title={t('pool.state')}>
          <CommonSelector columns={2} value={ param.status } options={statusList} onChange={val => { changeParams(val, "status") }}></CommonSelector>
        </FilterItemLayout>

        <FilterItemLayout tt={'nft.sort'} title={t('nft.sort')}>
          <CommonSelector columns={2} value={ param.order } options={sortType} onChange={val => { changeParams(val, "order") }}></CommonSelector>
        </FilterItemLayout>
      </CommonPopup>
    </CommonPage>
  );
})