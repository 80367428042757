/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-14 17:27:04
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-18 11:58:36
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\components\filterItemLayout\FilterItemLayout.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import styles from './FilterItemLayout.module.scss';
import PropTypes from "prop-types";

const FilterItemLayout = (props) => {
    return (
        <div className={styles.layout}>
            <div className={styles.title}>
                <div>{props.title}</div>
                <div>{props.action}</div>
            </div>
            <div className={styles.content}>
                {props.children}
            </div>
        </div>
    );
};

FilterItemLayout.prototype = {
    title: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired
};

FilterItemLayout.defaultProps = {
    title: '标题',
    children: <div>内容</div>,
};


export default FilterItemLayout;