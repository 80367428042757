import imageEth from "../assets/images/coin/eth.png";
import imageBTC from "../assets/images/coin/btc.png";
import imageUSDT from "../assets/images/coin/usdt.png";
import imageCAP from "../assets/images/coin/gap.png";

let coinAllData = {
    'eth': {
        title: 'ETH',
        img: imageEth,
    },
    'btc': {
        title: 'BTC',
        img: imageBTC,
    },
    'usdt': {
        title: 'USDT',
        img: imageUSDT,
    },
    'tbsc_usdt': {
        title: 'USDT',
        img: imageUSDT,
    },
    'bsc_usdt': {
        title: 'USDT',
        img: imageUSDT,
    },
    'ut': {
        title: 'UT',
        img: null,
    },
    'st': {
        title: 'ST',
        img: null,
    },
    'bsc_cap': {
        title: 'USDT',
        img: imageCAP,
    },
    'gap': {
        title: 'CAP',
        img: imageCAP,
    },
    'cap': {
        title: 'CAP',
        img: imageCAP,
    },
    'tbnb': {
        title: 'tBNB',
        img: null,
    },
}
/**
 * 数字货币图标
 */
export const getCoinData = (coin) => {
    if (coin && coinAllData[coin?.toLowerCase()]) {
        return coinAllData[coin?.toLowerCase()]
    }
    return coinAllData['tbnb'];
}