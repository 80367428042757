/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-06 09:09:49
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-27 16:17:00
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\pages\battlefield\Battlefield.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { memo, useEffect, useState, useRef } from 'react';

import { useLocation, useNavigate } from "react-router-dom";
import styles from './Nft.module.scss';
import { useTranslation } from "react-i18next";
import {toastError, toastHide, toastLoading, toastSuccess} from "utils/Toast";
import CommonPage from "components/commonPage/page/CommonPage";
import CommonButton from "components/commonButton/CommonButton";
import CommonInput from "components/commonInput/CommonInput";
import FilterItemLayout from "components/filterItemLayout/FilterItemLayout";
import {useDispatch, useSelector} from "react-redux";


import IconR from "components/icon/nft/IconR"
import IconSR from "components/icon/nft/IconSR"
import IconSSR from "components/icon/nft/IconSSR"

import gap from "assets/images/coin/gap.png"
import nftApi from "service/nftApi";
import classNames from 'classnames';
import md5 from "md5";
import { tokenName } from '@/utils/walletConfig';


export default memo(function Battlefield() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname.includes("shop")?"shop":"nft";
  const id = location?.state?.id
  const nftInfo = location?.state?.info
  const type = location?.state?.type // 0购买 1升级
  
  const [param,setParam] = useState({id,pay_password:"",buyNum:1});
  const changeParams = (value,key) => {setParam(prevState => { prevState[key] = value;return {...prevState}})}
  const [rate,setRate] = useState({}) 
  const [myHero,setMyHero] = useState({});

  const [countDown, setCountDown] = useState(0);
  useEffect(() => {
      // 创建定时器
      const interval = setInterval(() => {
          if (countDown>-1) {
              if (countDown === 0) {
                  nftLock()
              } else {
                  setCountDown(countDown - 1);
              }
              console.log(countDown)
          }else{ 
            clearInterval(interval)
          }
      }, 1000);
      // 返回一个函数，用于在组件卸载时清除定时器
      return () => clearInterval(interval);
  }, [countDown]);

  const rareType = useSelector(state => state.app.heroLevel );
  const rare = rareType.find(item=>item.level == nftInfo.level)||rareType.find(items=>items.level === "1")

  const nftUpdate = ()=>{ 
    toastLoading();
    nftApi.nftUpdate({...param,pay_password:md5( param.pay_password )}).then(res => {
        toastSuccess(res.massage)
        navigate("/shop")
    }).catch(err => {
        toastHide();
    })
  }

  const nftBuy = ()=>{ 
    toastLoading();
    nftApi.nftBuy({...param,pay_password:md5( param.pay_password )}).then(res => {
        toastSuccess(res.message)
        navigate("/shop")
    }).catch(err => {
        toastHide();
    })
  }

  const nftLock = ()=>{ 
    toastLoading();
    nftApi.nftLock(param).then(res => {
        setRate(res.data)
        changeParams(res.data?.id,"orderId")
        setCountDown(60);
        toastHide();
    }).catch(err => {
        toastHide();
    })
  }
  const getMyNftList = ()=>{ 
    toastLoading();
    nftApi.nftMyList({pageNo:1,pageSize:1,type:1}).then(res => {
        setMyHero(res.data.list[0] )
        toastHide();
    }).catch(err => {
        toastHide();
    })
  }

  useEffect(() => {
    nftLock()
    getMyNftList()
    
  }, []);

  return (
    <CommonPage tt={t('lbl_battlefield')}  flex  title={ type===0?t('nft.buyHero'):t('nft.updataHero')}> 
      <div className='flex justify-center'>
          <div className={ classNames(styles.grid_item, styles[rare.style]) } style={ {width:"30%","backgroundImage": `url(${nftInfo.image}),linear-gradient(180deg, #123556 0%, #071A2C 100%)`  } } >
            <div className='flex align-center justify-between'>
              {/* <span>{ type ===1 && "#"+myHero.id }</span> */}
              {rare.tag}
            </div>
          </div>
      </div>
      <div className={ classNames(styles.intro,styles.borderBottom,'text-16 line15 text-center') } >
        <div>{nftInfo.name}</div>
        <div className={styles.coin}>
          <img src={gap}/>
          <span>{nftInfo.price}</span>
        </div>
      </div>
      <div className={ classNames(styles.intro,styles.borderBottom) }>
        <div className='flex justify-between text-12'>
          <span className='text-aliceBlue'>{t("pool.coinPrice", {tokenName: {tokenName}})}</span>
          <span>{nftInfo.price}{nftInfo.token}</span>
        </div>
        <div className='flex justify-between text-12'>
          <span className='text-aliceBlue'>{t("pool.currentCoinPrice")}</span>
          <span>1 {nftInfo.token} = {rate.price} USDT</span>
        </div>
      </div>

      <div className={classNames(styles.intro,styles.borderBottom)} style={{padding:"16px 0px"}}>
        <div className='text-16 text-aliceBlue text-center'>{t("pool.actualPrice")}</div>
        <div className='text-24 text-bold text-center'>
          { type === 0? nftInfo.price*rate.price: (nftInfo.price-myHero.price)*rate.price } USDT</div>
      </div>

      <div className={styles.intro}>
        <FilterItemLayout title={t('pool.password')} action={<span className='text-12 text-orange'>{t("pool.countdown")} {countDown}s</span>}>
          <CommonInput type='password' value={param.pay_password} placeholder={t('pool.passwordInput')} onChange={val=>changeParams(val,"pay_password") }/>
        </FilterItemLayout>
      </div>

      { type === 0?
        <CommonButton shadow onClick={ nftBuy }>{t("pool.buy")}</CommonButton>:
        <CommonButton shadow onClick={ nftUpdate }>{t("pool.upgradation")}</CommonButton>
      }

    </CommonPage>
  );
})