import {toast as toaster} from "react-hot-toast";
import {Toast} from "antd-mobile";

export function toastSuccess(msg) {
    Toast.clear();
    toaster.success(msg);
}

export function toastError(msg) {
    Toast.clear();
    toaster.error(msg);
}

export function toastLoading(msg = '') {
    // toaster.loading(msg);
    Toast.show({
        content: msg,
        icon: 'loading',
        duration: 0,
        maskClickable: false
    });
}

export function toastHide() {
    // toaster.dismiss();
    Toast.clear();
}