/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-05-06 15:49:45
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-10-23 14:54:30
 * @FilePath: \weapp\wepay-h5\src\pages\home\LangPopup.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import CommonPopup from "../../components/commonPopup/CommonPopup";
import PropTypes from "prop-types";
import { Radio } from 'antd-mobile'
import styles from "./LangPopup.module.scss"
import { useLocale } from '@/LocaleContext';

const LangPopup = (props) => {
  const {t, i18n} = useTranslation();
  const { locale, setLocale } = useLocale();
  const {visible, confirm, getVisible } = props;
  const [googleVisible, setGoogleVisible] = useState(false)
  const SwitchLang = (value) =>{ 
    i18n.changeLanguage(value);
    setLocale(value);
    localStorage.setItem('locale', value);
    getVisible(false) 
  }
  const locales = [
    {title:'繁体中文',key:'zh_HK'},
    {title:'English',key:'en_US'}
  ]
  useEffect(() => {
    setGoogleVisible(visible) 
  }, [visible])
  
  return (
    <CommonPopup visible={googleVisible}  getVisible={ getVisible } title={t('lbl_chooseLanguage')}>
      <Radio.Group defaultValue={locale} onChange={SwitchLang}>
        <div className='popupList'>
          { 
            locales.map((item, index) => {
              return (
              <Radio value={item.key} key={item.key} className={ styles.locales }
              style={{'--icon-size': '18px','--font-size': '14px','--gap': '6px' }} 
              >
                <div className={ styles.item }>
                  <span className='text-14'>{item.title}</span> 
                </div>
              </Radio>
              )
            })
          }
        </div>
      </Radio.Group>
    </CommonPopup>
  );
};
LangPopup.prototype = {
  visible:PropTypes.bool,
  confirm: PropTypes.func,
  getVisible: PropTypes.func,
};
export default LangPopup;