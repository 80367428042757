import React, {useEffect, useState} from 'react';
import './CommonTextArea.scss';
import {TextArea} from "antd-mobile";
import PropTypes from "prop-types";
import classNames from "classnames";

const CommonTextArea = (props) => {


    const {border, borderBottom, textAlign, layoutType, ...rest} = props;

    const inputStyle = classNames({
        'textarea': true,
        'textarea-border': borderBottom ? false : border,
        'textarea-border-bottom': borderBottom,
        'textarea-start': textAlign === 'start',
        'textarea-center': textAlign === 'center',
        'textarea-end': textAlign === 'end',
        'textarea-custom-filter': layoutType === 'filter'
    });


    return (
        <TextArea {...rest} className={inputStyle} />
    );
};
CommonTextArea.prototype = {
    border: PropTypes.bool,
    borderBottom: PropTypes.bool,
    textAlign: PropTypes.oneOf(['start', 'center', 'end']),
    type: PropTypes.oneOf(['default', 'filter']),//布局特殊类型
}

CommonTextArea.defaultProps = {
    border: true,
    borderBottom: false,
    textAlign: 'start',
    type: 'default',
}

export default CommonTextArea;