import styles from './Exchange.module.scss';
import React, {useEffect, useState, memo, useRef} from 'react';
import CommonPage from "@/components/commonPage/page/CommonPage";
import CommonButton from "@/components/commonButton/CommonButton";
import CommonInput from "@/components/commonPwdInput/commonInput";
import {useTranslation} from "react-i18next";
import { tokenName } from 'utils/walletConfig'; // 代币名称
import { Form } from "antd-mobile";
import { toastHide, toastLoading, toastSuccess } from "@/utils/Toast";
import mineApi from "@/service/mineApi";
import accountApi from "@/service/accountApi";
import moment from "moment";
import {ReactComponent as IconRecordExchange } from '@icon/RecordExchange.svg';
import {ReactComponent as IconArrowLeft } from '@icon/ArrowLeft.svg';
import md5 from 'md5';
import CommonPageScrollList from "@/components/commonPage/ScrollList/CommonPageScrollList";

let countDown = null;
export default memo(function Recharge() {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const refList = useRef(null);

  // 兑换数量
  const [exchangeAmount, setExchangeAmount] = useState('');
  // 划转数据
  const [exchangeData, setExchangeData] = useState({});
  // CAP代币信息
  const [tokenInfo, setTokenInfo] = useState({});
  // 兑换记录
  const [exchangeRecord, setExchangeRecord] = useState([1,2,1,1,1,1,1,1,1,1]);
  // 预计到账金额
  const [receivedAmount, setReceivedAmount] = useState(0.00);
  
  const onFinish = (values) => {
    // 兑换
    toastLoading();
    mineApi.exchange({
      amount: values.amount,
      coinId: tokenInfo.coinId,
      password: md5(values.password)
    }).then(res => {
      if(res.status === 200) {
        toastSuccess(t('msg_exchange_success'));
        form.resetFields();
        getExchangeData();
        refList?.current.refreshApiData();
      }
    }).finally(() => {
      toastHide();
    })
  }

  // 获取最大值
  const getMax = () => {
    inputRef.current.focus();
    form.setFieldsValue({
      amount: exchangeData.balance
    })
    setExchangeAmount(exchangeData.balance);
  }

  // 获取用户资产列表
  const getUserAssetsList = async () => {
    toastLoading();
    await accountApi.getUserAssetsList().then(res => {
      // 筛选代币为CAP的数据
      const data = res.data.filter(item => item.blockCoinName === tokenName);
      setTokenInfo(data[0])
    }).finally(() => {
      toastHide();
    })
  }

  // 获取换算汇率 以及划转手续费
  const getExchangeData = async () => {
    toastLoading();
    mineApi.getExchangeData({
      coinId: tokenInfo.coinId
    }).then(res => {
      const { data } = res;
      setExchangeData(data);
    }).catch((err) => {
      setExchangeData({});
      clearInterval(countDown);
    }).finally(() => {
      toastHide();
    })
  }

  const handleAmountChange = (data) => {
    setExchangeAmount(data);
    form.setFieldsValue({
      amount: data
    })
  };

  // 划转金额换算
  const handleExchangeAmount = () => {
    const amount = exchangeAmount;
    if (amount <= 0 || isNaN(amount) || amount < exchangeData.minTransfer) {
      setReceivedAmount(0.00);
      return;
    }
    if (JSON.stringify(exchangeData) === '{}') return;
    mineApi.getExchangeAmount({
      amount: amount,
      coinId: tokenInfo.coinId
    }).then(res => {
      if(res.status === 200) {
        setReceivedAmount(res.data.money)
        setExchangeData({
          ...exchangeData
        })
      }
    }).catch(() => {
      // inputRef.current.focus();
    })
  }

  const getApiData = (page) => {
    return mineApi.getExchangeHistory({
      pageNo: page,
      pageSize: 10
    });
  }

  const renderListItem = (item, index) => {
    return (
      <div className={styles.item} key={index}>
        <div className={styles.itemLeft}>
          <IconRecordExchange width={32} />
        </div>
        <div className={styles.itemRight}>
          <div className={styles.itemRightTop}>
            <span className={styles.label}>{t('lbl_exchange')}</span>
            <span className={styles.value}>
              <span>{item.tokenAmount} {tokenName}</span> <IconArrowLeft width={12} /> {item.usdtAmount} USDT
            </span>
          </div>
          <div className={styles.itemRightBottom}>
            <span>{moment(item.createTime).format('HH:mm DD-MM-YYYY')}</span>
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    getUserAssetsList();
  }, [])

  useEffect(() => {
    if (tokenInfo.id) {
      getExchangeData();
    }
  }, [tokenInfo])

  return (
    <CommonPage title={`${tokenName} ${t('lbl_exchange')} USDT`} flex className={styles.exchange}>
      <Form className={styles.form} form={form} onFinish={onFinish} footer={
        <>
        <div className="h25"></div>
        <CommonButton block type='submit' color='primary'>{t('common.confirm')}</CommonButton>
        </>
      }>
        {/* 输入兑换数量 */}
        <div className={styles.formItem}>
          <Form.Item
            label={t('lbl_input_exchange_num')}
            name="amount"
            rules={[
              { required: true, message: t('msg_withdraw_amount_not_empty') },
              {
                validator: (_, value) => {
                  if (isNaN(value)) {
                    return Promise.reject(new Error(t('msg_input_numbers_only')));
                  }
                  if (value < exchangeData.minTransfer) {
                    return Promise.reject(new Error(t('msg_input_min_value')));
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <CommonInput ref={inputRef} onChange={handleAmountChange} onBlur={handleExchangeAmount} placeholder={t('lbl_input_min_withdraw_amount', { amount: exchangeData.minTransfer, coin: tokenName })} />
          </Form.Item>
          <span className={styles.btnMax} onClick={() => getMax()}>{t('lbl_max')}</span>
          {
            exchangeData && JSON.stringify(exchangeData) !== '{}' ? (
              <>
                <div className={styles.inputFooter}>
                  <div className={styles.itemInputFooter}>
                    <span>{tokenName}{t('lbl_exchange_num')}</span>
                    <span>{exchangeData.balance} {tokenName}</span>
                  </div>
                  <div className={styles.itemInputFooter}>
                    <span>{t('lbl_current_price')}</span>
                    <span>1 {tokenName} = {exchangeData.onlineUsdtPrice} USDT</span>
                  </div>
                </div>
                {/* 到账数量 */}
                <div className={styles.actualAmount}>
                  <span className={styles.label}>{t('lbl_actual_amount')}</span>
                  <span className={styles.value}>{receivedAmount} USDT</span>
                </div>
              </>
            ) : null
          }
        </div>
        {/* 密码 */}
        <div className={styles.formItem}>
          <Form.Item
            label={t('lbl_password')}
            name="password"
            rules={[{ required: true, message: t('msg_empty_password') }]}
          >
            <CommonInput type="password" placeholder={t('lbl_input_password')} />
          </Form.Item>
        </div>
      </Form>
      {/* 兑换记录 */}
      <div className={styles.exchangeRecord}>
        <div className={styles.title}>
          <span>{t('lbl_exchange_record')}</span>
        </div>
        <div className={styles.list}>
          <CommonPageScrollList
            extraClassName="exchangeRecordContent"
            ref={refList}
            renderListItem={renderListItem}
            getApiData={getApiData}>
          </CommonPageScrollList>
        </div>
      </div>
    </CommonPage>
  );
})