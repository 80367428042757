import styles from './Settings.module.scss';
import React, {useEffect, useState, memo, forwardRef} from 'react';
import CommonPage from "@/components/commonPage/page/CommonPage";
import CommonInput from "@/components/commonInput/CommonInput";
import CommonButton from 'components/commonButton/CommonButton';
import CommonModal from 'components/commonModal/CommonModal';
import {useTranslation} from "react-i18next";
import {Form, Loading} from 'antd-mobile';
import { toastHide, toastLoading, toastSuccess } from "@/utils/Toast";
import commonApi from 'service/commonApi';
import mineApi from 'service/mineApi';

export default memo(function AccountIndex() {
  // 默认头像
  const defaultAvatar = require('@/assets/images/default-avatar.jpg');
  const {t} = useTranslation();

  // 是否显示loading
  const [loading, setLoading] = useState(true);
  // 我的团队信息
  const [teamInfo, setTeamInfo] = useState({});
  
  // 是否显示修改团队名称弹窗
  const [isShowNickNameModal, setIsShowNickNameModal] = useState(false);
  const [form] = Form.useForm();

  // 取消设置团队名称弹窗
  const handleCancel = () => {
    setIsShowNickNameModal(false);
    form.resetFields();
  }
  // 修改团队名称
  const onFinish = (values) => {
    // 修改团队名称
    changeNickName();
  }
  // 修改团队名称
  const changeNickName = async () => {
    let data = form.getFieldsValue(true);
    let params = {
      teamName: data.teamName,
    }
    toastLoading();
    mineApi.updateTeamName(params).then(res => {
      if (res.status === 200) {
        toastSuccess(t('msg_modify_team_name_success'));
        getData();
        handleCancel();
      }
    }).finally(() => {
      toastHide();
    });
  }
  // 修改团队名称弹窗表单
  const FormNickName = forwardRef(() => {
    return (
      <Form className={styles.form} form={form} onFinish={onFinish} footer={
        <div className={styles.btnGroup}>
          <CommonButton block cancel onClick={() => handleCancel()}>{t('common.cancel')}</CommonButton>
          <CommonButton block type='submit'>{t('common.confirm')}</CommonButton>
        </div>
      }>
        <Form.Item name="teamName" initialValue={teamInfo.teamName} rules={[
          {
            validator: (rule, value) => {
              if (!value) {
                return Promise.reject(t('msg_incorrect_team_name'));
              } else {
                // 长度限制 中文1个算两个字符 英文1个算一个字符 2-14个字符
                if (value.replace(/[^\x00-\xff]/g, 'xx').length < 2 || value.replace(/[^\x00-\xff]/g, 'xx').length > 14) {
                  return Promise.reject(t('msg_incorrect_team_name'));
                } else {
                  return Promise.resolve();
                }
              }
            }
          }
          ]}>
          <CommonInput type={'text'} placeholder={t('lbl_input_new_team_name')} />
        </Form.Item>
      </Form>
    )
  })

  // 获取我的团队信息
  const getData = () => {
    setLoading(true)
    mineApi.getMyTeam().then(res => {
      setTeamInfo(res.data)
    })
    .catch(err => {
      console.log(err)
    }).finally(() => {
      setLoading(false)
    });
  }

  // 上传头像
  const uploadAvatar = (e) => {
    toastLoading();
    commonApi.uploadImage({
      file: e.target.files[0]
    }).then(res => {
      if (res.status === 200) {
        let params = {
          teamImage: res.data.Location
        }
        mineApi.updateTeamIcon(params).then(res => {
          if (res.status === 200) {
            getData();
          }
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          toastHide();
        });
      }
    }).catch(() => {
      toastHide();
    })
  }
  
  useEffect(() => {
    // 获取团队信息
    getData();
  }, []);

  return (
    <CommonPage title={t('lbl_community_setting')} flex padding0 className={styles.accountIndex}>
      <div className={styles.settingList}>
        {/* 修改团队名称 */}
        <div className={styles.settingItem} onClick={() => setIsShowNickNameModal(true)}>
          <div>{t('lbl_modify_name')}</div>
          <div className={styles.settingItemRight}>
            <span>{teamInfo.teamName}</span>
          </div>
        </div>
        {/* 修改团队图标 */}
        <div className={styles.settingItem}>
          <div>{t('lbl_modify_icon')}</div>
          <div className={styles.settingItemRight}>
            {
              loading ? <Loading size='lg' /> : (
                <>
                  <img src={teamInfo.teamImage || defaultAvatar} alt="" className={styles.avatar} />
                </>
              )
            }
          </div>
          <input type="file" accept="image/*" className={styles.uploadAvatar} onChange={(e) => uploadAvatar(e)} />
        </div>
      </div>
      {/* 修改团队名称弹窗 */}
      <CommonModal
        title={t('lbl_modify_nickname')}
        visible={isShowNickNameModal}
        onClose={handleCancel}
        content={<FormNickName/>}
      >
      </CommonModal>
    </CommonPage>
  );
})