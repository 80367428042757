import React, {memo} from 'react';
import styles from './test.module.scss';
import CommonButton from "@/components/commonButton/CommonButton";
import axios from 'axios';

export default memo(function Splash() {
  const testCros1 = () => {
    // 括号里的是请求的地址，可替换成自己的请求地址
    axios.post('https://apinew.newworld.vip/test-cors').then(res => {
      alert('测试1成功 ==> 请求URL: https://apinew.newworld.vip/test-cors ==> 返回结果：' + JSON.stringify(res));
      console.log(res)
    }).catch(error => {
      alert('测试1错误 ==> 请求URL: https://apinew.newworld.vip/test-cors ==> 返回结果：' + JSON.stringify(error));
    })
  }

  const testCros2 = () => {
    // 括号里的是请求的地址，可替换成自己的请求地址
    axios.post('https://testers.newworld.vip/index.php').then(res => {
      alert('测试2成功 ==> 请求URL: https://testers.newworld.vip/index.php ==> 返回结果：' + JSON.stringify(res));
      console.log(res)
    }).catch(error => {
      alert('测试2错误 ==> 请求URL: https://testers.newworld.vip/index.php ==> 返回结果：' + JSON.stringify(error));
    })
  }

  return (
    <div className={styles.action}>
      <CommonButton type="primary" block cancel onClick={() => testCros1()}>测试1</CommonButton>
      <CommonButton block type="primary" onClick={() => testCros2()}>测试2</CommonButton>
    </div>
  );
});