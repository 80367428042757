import React, {useEffect, useState} from 'react';
import styles from './CheckBox.module.scss';
import PropTypes from "prop-types";

/**
 * 外部传入初始状态
 * 内部更新当前状态，并传递给外部
 */
const CommonCheckbox = React.forwardRef((props, ref) => {

    const {checked, onChange, ...rest} = props;

    const [isChecked, setIsChecked] = useState(props.checked);

    const handleChange = (e) => {
        let checked1 = e.target.checked;
        // console.log(checked1, 'common check box');
        setIsChecked(checked1)
        onChange && onChange(checked1);
    }

    return (
        <input
            className={styles.checkbox}
            type="checkbox"
            ref={ref}
            checked={isChecked}
            onChange={handleChange}
            {...rest}
        />
    )
});

CommonCheckbox.prototype = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

CommonCheckbox.defaultProps = {
    checked: false,
}

export default CommonCheckbox;