/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-05-06 15:49:45
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-15 18:07:31
 * @FilePath: \weapp\wepay-h5\src\pages\mine\Mine.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE()
 */
import styles from './Mine.module.scss';
import classnames from 'classnames'
import React, {memo, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import CommonNavBar from "components/commonNavBar/CommonNavBar";
import CommonPage from "components/commonPage/page/CommonPage";
import CommonDialog from "components/commonDialog/CommonDialog";
import accountApi from "service/accountApi";
import {useDispatch, useSelector} from "react-redux";
import useUserHooks from "../../hooks/useUserHooks";
import moment from "moment";
import {setUser} from "../../redux/store/user";
import {useTranslation} from "react-i18next";
import UseUserApi from "../../hooks/useUserApi";

import IconSwitch from "components/icon/account/IconSwitch";
import IconBox from "assets/images/mine/IconBox.png";
import IconInvite from "assets/images/mine/IconInvite.png";
import IconLang from "assets/images/mine/IconLang.png";
import IconNFT from "assets/images/mine/IconNFT.png";
import IconProp from "assets/images/mine/IconProp.png";
import IconTeam from "assets/images/mine/IconTeam.png";
import IconWallet from "assets/images/mine/IconWallet.png";
import IconShop from "assets/images/mine/IconShop.png";
import imageLogo from 'assets/images/logo.png';

const _package = require('../../../package.json');

export default memo(function Mine() {
  const {userLogOut} = useUserHooks();
  const [userAssetsTotal, setUserAssetsTotal] = useState(0);
  const {homeData} = UseUserApi();

  let dispatch = useDispatch();
  let {t} = useTranslation();

  useEffect(() => {
    // 获取用户数据
    getUserInfo();
  }, []);

  let navigate = useNavigate();

  const [visible, setVisible] = useState(false)
  const [logou, setLogou] = useState(false)
  // 用户信息
  const [userInfo, setUserInfo] = useState({})

  const getUserInfo = () => {
    accountApi.getUserInfo().then(res => {
      setUserInfo(res.data)
      dispatch(setUser(res.data));
    })
    .catch(err => {
      console.log(err)
    });
  }

  // 提示敬请期待
  const handleSoon = () => {
    CommonDialog({
      title: t('common.tip'),
      content: t('lbl_soon'),
      type: 'alert',
      okText: t('common.confirm')
    })
  }

  const menuList=[
    { icon:IconNFT ,title: t('nft.myNFT'), fn:()=>navigate("/nft")},
    { icon:IconTeam ,title: t('lbl_my_community'), fn:()=>navigate("/mine/community")},
    { icon:IconInvite ,title: t('lbl_my_invitation'), fn:()=>navigate("/mine/invitation")},
    { icon:IconBox ,title: t('lbl_blind_box_market'), fn:()=> 
      handleSoon()
    },
    { icon:IconProp ,title: t('lbl_my_props'), fn:()=>
      handleSoon()
    },
    { icon:IconLang ,title: t('lbl_language_switch'), fn:()=>navigate("/setup/locales")},
  ]

  // 确认退出登录
  const handleConfirmLogout = () => {
    CommonDialog({
      type: 'confirm',
      title: t('common.logout'),
      content: t('msg_logout_confirm'),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onConfirm: () => {
        userLogOut();
      }
    })
  }
  
  return (
    <CommonPage tt={t('lbl_battlefield')} flex title={t('lbl_battlefield')} padding0 header={<CommonNavBar back={null}>
      <img src={imageLogo} className={styles.logo} alt="" />
    </CommonNavBar>}>
      <div className={ styles.mainContent }>
        <div className={styles.mainContentWrapper}>
          <div className={classnames(styles.account,styles.borderBottom)} >
            <div className={styles.accontL}>
              <div className={styles.accontLTop}>
                <label className={styles.nicknameLabel}>{t('lbl_placeholder_nickname')}:</label>
                <span>{userInfo.nickname}</span>
              </div>
              <div className={styles.accontLBottom}>
                <label className={styles.nicknameLabel}>{t('lbl_user_id')}:</label>
                <span className={styles.userId}>{userInfo.id}</span>
              </div>
            </div>
            <div className={styles.accontR} onClick={() => navigate('/account')}>
              <span className='text-16'>{t('lbl_my_account')}</span>
              <IconSwitch/>
            </div>
          </div>
          <div className={styles.gridList}>
            {/* 我的钱包 */}
            <div className={styles.gridItem} onClick={() => navigate('/mine/wallet')}>
              <img className={styles.icon} src={IconWallet} alt="icon"/>
              <span className='text-16 text-bold'>{t('lbl_my_wallet')}</span>
            </div>
            {/* NFT市场 */}
            <div className={styles.gridItem} onClick={() => navigate('/shop')}>
              <img  className={styles.icon} src={IconShop} alt="icon"/>
              <span className='text-16 text-bold'>{t('lbl_nft_market')}</span>
            </div>
          </div>
          {/* 菜单 */}
          <div className={ classnames( styles.menuList, styles.borderTop,styles.borderBottom )} >  
            {
              menuList.map((item, index)=>
                {
                  // 如果userInfo.isTteam为true,则显示我的团队
                  if(item.title === t('lbl_my_team') && !userInfo.isTeam) return null;
                  return (
                    <div className={ styles.menuItem} onClick={item.fn} key={index}>
                      <img className={styles.icon} src={item.icon} alt="icon"/>
                      <span className='text-12 text-bold text-center'>{item.title}</span>
                    </div>
                  )
                }
              )
            }
          </div>
          {/* 退出登录 */}
          <div className={ styles.btn } onClick={() => handleConfirmLogout()}>
            <span className='text-gradient text-bold'>{t('common.logout')}</span>
          </div>
        </div>
        {/* APP 版本号 */}
        <div className={styles.version}>NewWorld ver{_package.version}</div>
      </div>
    </CommonPage>
  );
})