import React, {useEffect, useState} from 'react';
import CoinDropDown from "@/components/coinDropDown/CoinDropDown";
import commonApi from "@/service/commonApi";
import {useTranslation} from "react-i18next";

const NetCoinChoose = ({network, chooseCoin, filterCoin}) => {

  const [optionCoins, setOptionCoins] = useState([]);
  let {t} = useTranslation();

  const itemClickCoin = (item, index) => {
    chooseCoin && chooseCoin(item);
  }

  useEffect(() => {
    if(!network.blockChain) return;
    commonApi.getChainCoin({
      block_chain: network.blockChain,
      type: 2
    })
    .then(res => {
      let data = res.data;
      if(filterCoin){
        data = data.filter(item => filterCoin.includes(item.blockCoinName.toLowerCase()))
      }
      data = data.map(item => {
        item.blockChain = item.blockCoinName;
        return item;
      })
      setOptionCoins(data);
      itemClickCoin(data[0]);
    })
  }, [network]);

  return (
    <CoinDropDown title={t('lbl_choose_currency')} coinData={optionCoins} itemClick={itemClickCoin}></CoinDropDown>
  );
};

export default NetCoinChoose;