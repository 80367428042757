/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-05-09 13:23:25
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-05-10 10:13:52
 * @FilePath: \weapp\wepay-h5\src\router\account.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Page from "./Page";
import Register from "../pages/account/register/Register";
import RegisterSuccess from "../pages/account/registerSuccess/RegisterSuccess";
import AccountIndex from "../pages/account/index/Index";
import AccountPassword from "../pages/account/password/Password";
import AccountManagement from "../pages/account/management/Management";
import AccountManagementCreate from "../pages/account/management/create/Create";
import ServiceRule from "../pages/tips/ServiceRule";
import PrivateRule from "../pages/tips/PrivateRule";
import i18next from '../i18n/index.js'

const accountRouter = [
    Page('我的账户', 'account', <AccountIndex/>),
    Page('修改密码', 'account/password', <AccountPassword/>),
    Page('账户管理', 'account/management', <AccountManagement/>),
    Page('创建子账户', 'account/management/create', <AccountManagementCreate/>),
]

const registerRouter = [
    Page('注册', 'register', <Register/>),
    Page('注册成功', 'registerSuccess', <RegisterSuccess/>),
    Page('服務條款', 'serviceRule', <ServiceRule/>),
    Page('隐私条款', 'privateRule', <PrivateRule/>),
]
export {accountRouter, registerRouter};