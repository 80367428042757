/**
 * @file Popover气泡弹窗组件
 * @param {node} children - 触发弹窗的元素
 * @param {node} content - 弹窗内容
 * @param {String} placement - 弹窗位置 默认 bottom
 * @param {Object} rest - 其他参数
 */

import './CommonPopover.module.scss';
import React from 'react'
import { Popover } from 'antd-mobile';
import PropTypes from 'prop-types';

const commonPopover = (props) => {
  const { children, placement="bottom", content, ...rest } = props;
  return (
    <Popover
      content={content}
      placement={placement}
      trigger='click'
      defaultVisible={false}
      className="common-popover"
      {...rest}
    >
      {children}
    </Popover>
  )
}

commonPopover.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  placement: PropTypes.string,
}

export default commonPopover;