import styles from './Withdraw.module.scss';
import React, {useEffect, useState, memo} from 'react';
import CommonPage from "@/components/commonPage/page/CommonPage";
import CommonButton from "@/components/commonButton/CommonButton";
import CommonInput from "@/components/commonPwdInput/commonInput";
import CommonTextArea from "@/components/commonTextArea/CommonTextArea";
import commonApi from "@/service/commonApi";
import mineApi from "@/service/mineApi";
import { toastHide, toastLoading, toastSuccess } from "@/utils/Toast";
import {useTranslation} from "react-i18next";
import NetWorkChoose from "@/components/netWorkChoose/NetWorkChoose";
import NetCoinChoose from "@/components/netCoinChoose/NetCoinChoose";
import { Form } from "antd-mobile";
import BigNumber from "bignumber.js";

export default memo(function Withdraw() {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  // 选择的主网
  const [selectedNet, setSelectedNet] = useState('');
  // 选择的币种
  const [selectedCoin, setSelectedCoin] = useState('');
  // 代币可用余额
  const [balance, setBalance] = useState('');
  // 提现金额
  const [withdrawAmount, setWithdrawAmount] = useState('');

  const onFinish = (values) => {
    // 提现
    toastLoading();
    commonApi.withdraw({
      ...values,
      coin_name: selectedCoin.coinName,
      block_chain: selectedNet.blockChain
    }).then(res => {
      if(res.status === 200) {
        toastSuccess(t('msg_withdraw_success'));
        form.resetFields();
        getBalance();
      }
    }).finally(() => {
      toastHide();
    })
  }

  // 获取最大值
  const getMax = () => {
    form.setFieldsValue({
      token_amount: balance
    })
  }

  // 获取余额
  const getBalance = () => {
    if(!selectedCoin) return;
    mineApi.getCoinBalance({
      coin_name: selectedCoin.coinName
    }).then(res => {
      const { balance } = res.data;
      setBalance(balance);
    })
  }
  
  useEffect(() => {
    getBalance();
  }, [selectedCoin])

  return (
    <CommonPage title={t('lbl_withdraw')} flex className={styles.withdraw}>
      <Form className={styles.form} form={form} onFinish={onFinish} footer={
        <>
        <div className="h25"></div>
        <CommonButton block type='submit' color='primary'>{t('common.confirm')}</CommonButton>
        </>
      }>
        <div className={styles.formItem}>
          {/* 主网，币种 */}
          <div className={styles.selectGroup}>
            {/* 选择主网 */}
            <div style={{width: '50%'}} className={styles.itemSelect}>
              <NetWorkChoose chooseNetWork={(network => {
                setSelectedNet(network);
              })}/>
            </div>
            {/* 选择币种 */}
            <div style={{width: '50%'}} className={styles.itemSelect}>
              <NetCoinChoose network={selectedNet} chooseCoin={(coin) => {
                setSelectedCoin(coin);
              }}/>
            </div>
          </div>
        </div>
        <div className={styles.formItem}>
          <Form.Item
            label={t('lbl_withdraw_address')}
            name="out_address"
            rules={[{ required: true, message: t('msg_empty_wallet_address') }]}
          >
            <CommonTextArea type="textarea" placeholder={t('lbl_input_wallet_address')} />
          </Form.Item>
        </div>
        <div className={styles.formItem}>
          <Form.Item
            label={t('lbl_withdraw_amount')}
            name="token_amount"
            rules={[
              { required: true, message: t('msg_withdraw_amount_not_empty') },
              {
                validator: (_, value) => {
                  if (isNaN(value)) {
                    return Promise.reject(new Error(t('msg_input_numbers_only')));
                  }
                  if (value < selectedCoin.minWithdrawAmount) {
                    return Promise.reject(new Error(t('msg_input_min_value')));
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <CommonInput onChange={(e) => setWithdrawAmount(e)} placeholder={t('lbl_input_min_withdraw_amount', { amount: selectedCoin.minWithdrawAmount, coin: selectedCoin.blockCoinName })} contentRight={<span className={styles.btnMax} onClick={() => getMax()}>{t('lbl_max')}</span>} />
          </Form.Item>
          <div className={styles.inputFooter}>
            <span>{t('lbl_balance')} {balance} {selectedCoin.blockCoinName}</span>
            <span>{t('lbl_withdraw_fee')} {selectedCoin.withdrawFee} {selectedCoin.blockCoinName}</span>
          </div>
          {/* 实际到账金额 */}
          <div className={styles.actualAmount}>
            <span className={styles.label}>{t('lbl_actual_account')}</span>
            <span className={styles.value}>{withdrawAmount > 0 ? BigNumber(withdrawAmount).minus(BigNumber(selectedCoin.withdrawFee)).toString() : 0} {selectedCoin.blockCoinName}</span>
          </div>
        </div>
      </Form>
    </CommonPage>
  );
})