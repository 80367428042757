import styles from './Invitation.module.scss';
import React, {useEffect, memo} from 'react';
import CommonPage from "@/components/commonPage/page/CommonPage";
import { toastSuccess } from "@/utils/Toast";
import {useTranslation, Trans} from "react-i18next";
import {QRCodeSVG} from "qrcode.react";
import { useSelector } from "react-redux";
import {ReactComponent as IconCopy } from '@icon/Copy.svg';

export default memo(function Invitation() {
  const {t} = useTranslation();
  const userInfo = useSelector(state => state.user.user);

  // 复制到剪贴板
  const copyToClipboard = async (textToCopy) => {
    try {
      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      toastSuccess(t('msg_copy_success'));
    } catch (err) {
      console.error('无法复制文本到剪贴板', err);
    }
  };

  return (
    <CommonPage title={t('lbl_my_invitation')} flex className={styles.invitation}>
      {/* 二维码 */}
      <div className={styles.qrcode}>
        <QRCodeSVG value={userInfo.inviteUrl} />
      </div>
      <div className={styles.title}>{t('lbl_invitation_qrcode')}</div>
      {/* 邀请码以及邀请链接 */}
      <div className={styles.invitationInfo}>
        <div className={styles.invitationInfoItem}>
          <div className={styles.invitationInfoTitle}>{t('lbl_my_invitation_code')}</div>
          <div className={styles.invitationInfoContent}>{userInfo.inviteCode}<IconCopy className={styles.btnCopy} onClick={() => copyToClipboard(userInfo.inviteCode)} /></div>
        </div>
        <div className={styles.invitationInfoItem}>
          <div className={styles.invitationInfoTitle}>{t('lbl_invitation_link')}</div>
          <div className={styles.invitationInfoContent}>{userInfo.inviteUrl}<IconCopy className={styles.btnCopy} onClick={() => copyToClipboard(userInfo.inviteUrl)} /></div>
        </div>
        {/* 我的等级 */}
        <div className={styles.invitationInfoItem}>
          <div className={styles.invitationInfoTitle}>{t('lbl_my_level')}</div>
          <div className={styles.invitationInfoContent}>VIP {userInfo.levelVal}</div>
        </div>
        {/* 我的社区产能 */}
        <div className={styles.invitationInfoItem}>
          <div className={styles.invitationInfoTitle}>{t('lbl_my_community_capacity')}</div>
          <div className={styles.invitationInfoContent}>{userInfo.signPower} W</div>
        </div>
        {/* 我的产能加成 */}
        <div className={styles.invitationInfoItem}>
          <div className={styles.invitationInfoTitle}>{t('lbl_my_capacity_bonus')}</div>
          <div className={styles.invitationInfoContent}>{userInfo.percentVal}%</div>
        </div>
      </div>
      {/* 邀请规则说明 */}
      <div className={styles.tip}>{t('lbl_invite_tip')}</div>
    </CommonPage>
  );
})