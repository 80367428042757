/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-09-15 10:48:24
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-08 09:22:22
 * @FilePath: \weapp\wepayToB-h5\src\hooks\useUserHooks.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, {useEffect,} from 'react';
import {useDisconnect} from "wagmi";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { initUserInfo,initUserAsset, userLogout as userLogoutState} from "../redux/store/user";
import {toastError,toastHide, toastLoading} from "../utils/Toast";

import {whiteRoutes, routes} from '../router/index'
const UseUserHooks = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let location = useLocation();
  const {disconnect} = useDisconnect();
  const token = useSelector(state => state.user.token);
  const user = useSelector(state => state.user.user);

  useEffect( () => {
    if (location.pathname.startsWith('/demo')) {
      return;
    }
    if (!token) {
      const route = whiteRoutes.find((item) => location.pathname === '/' + item.path);
      // pathname不在白名单中，则断开钱包连接，跳转到登录页
      if (route===undefined) {
        console.log(location.pathname)
        if(location.pathname == '/'){
          return;
        }
        navigate('/', {replace: true});
        disconnect();
      } else {
        // pathname在白名单中，跳转到对应的路由
        navigate('/'+route.path)
      }
    }
  }, [token]);

    const initUserData = () => {
      //初始化用户数据
      dispatch(initUserInfo());
      dispatch(initUserAsset());
      // dispatch(initUserLocal());
    }

    /**
     * 退出登录。全局只能使用此方法退出登录
     */
    const userLogOut = () => {
      console.log('userLogOut')
      dispatch(userLogoutState());
    }

    const checkGoogleAuth = () => {
      if (user && user?.hasGoogleSecret == 1) {
        return true;
      }
      toastError('请先绑定谷歌验证器')
      return false;
    }

    return {userLogOut, initUserData, checkGoogleAuth}
};

export default UseUserHooks;