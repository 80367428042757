/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-06 09:09:49
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2024-01-27 14:36:14
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\pages\battlefield\Battlefield.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { memo, useEffect, useState, useRef } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styles from './Battlefield.module.scss';
import { useTranslation } from "react-i18next";
import CommonPage from "components/commonPage/page/CommonPage";
import CommonNavBar from "@/components/commonNavBar/CommonNavBar";
import CommonDialog from "components/commonDialog/CommonDialog";

import classNames from 'classnames';
import {toastError, toastHide, toastLoading, toastSuccess} from "utils/Toast";
import { ReactComponent as IconClose } from '@icon/pool/CloseMusic.svg';
import { ReactComponent as IconOpen } from '@icon/pool/OpenMusic.svg';

import { ReactComponent as IconHistroy } from '@icon/pool/IconHistroy.svg';
import { ReactComponent as IconRule } from '@icon/pool/IconRule.svg';
import { ReactComponent as IconProp } from '@icon/pool/IconProp.svg';
import { ReactComponent as IconExplore } from '@icon/pool/IconExplore.svg';
import gap from "assets/images/coin/gap.png"
import loading from 'assets/images/MiningPool/loading.png';
import battlefieldApi from "service/battlefieldApi";
import moment from 'moment'
import i18n from "@/i18n";

export default memo(function Battlefield() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const scrollRef = useRef(null)
  const [param,setParam] = useState({});

  /*关卡信息 */
  const [controlList,setControlList] = useState([]);
  const [controlIndex, setControlIndex] = useState(0);
  /* nft信息 */
  const [nftInfo,setNftInfo] = useState(null);
  const [propInfo,setPropInfo] = useState(null);
  /* 用户闯关信息 */
  const [userControl,setUserControl] = useState(null);
  const language = i18n.language;
  const audioRef = useRef(null)
  const [playing, setPlaying] = useState(false); 
  const mp3 = require("assets/MP3/11.mp3") 
  const navSelector = (index) => {
    setControlIndex(index);
    const itemWidth = 70;
    const clientWidth = scrollRef.current.clientWidth
    const count = Math.floor(Math.floor(clientWidth / 70) / 2)
    /* 偏移至 */
    const scrollLeft = (index - count) * itemWidth - clientWidth % itemWidth / 2;
    const currLeft = scrollRef.current.scrollLeft
    let timerCount = 0
    let speed = (scrollLeft - currLeft) / 5;
    let timer = setInterval(() => {
      // console.log(scrollRef.current.scrollLeft)
      scrollRef.current.scrollLeft += speed
      timerCount++
      if (timerCount == 5) {
        clearInterval(timer)
      }
    }, 20)
  }
  
  const toggleAudio =()=> {
    const audio = audioRef.current
    audio?.paused?audio.play():audio.pause()
    setPlaying(!audio.paused) 
  }

  /* 关卡列表 .config
   * 我的闯关信息 battlefield
   */
  
  const getInfo = ()=>{ 
    toastLoading();
    battlefieldApi.info(param).then(res => {
        setNftInfo(res.data.nft)
        setControlList(res.data.config)
        if(res.data.battlefield){ 
          const battlefield = res.data.battlefield
          setUserControl(battlefield)
          setControlIndex(battlefield.round) 
          setCountDown( moment().diff(battlefield.createdTime,"seconds") )
        }else{ 
          setUserControl(null)
          setControlIndex(0) 
        }
        toastHide();
    }).catch(err => {
        toastHide();
    })
  }

  /* 加入探索 */
  const join = ()=>{ 
    toastLoading();
    battlefieldApi.join(param).then(res => {
      getInfo();
      toastSuccess(res.massage)
    }).catch(err => {
        toastHide();
    })
  }

  /* 结束探索 */
  const exit = ()=>{ 
    toastLoading();
    battlefieldApi.exit(param).then(res => {
      getInfo();
      toastSuccess(res.massage)
    }).catch(err => {
        toastHide();
    })
  }

  // 提示敬请期待
  const handleSoon = () => {
    CommonDialog({
      title: t('common.tip'),
      content: t('lbl_soon'),
      type: 'alert',
      okText: t('common.confirm')
    })
  }

  const renderDifftime = (seconds) => {
    const days =  Math.floor(seconds/(60*60*24))
    const hours = Math.floor(seconds%(60*60*24)/(60*60)) ;
    const minutes = Math.floor(seconds%(60*60)/60);
    const second = Math.floor(seconds%60);
    return (
      (days>0?days+"d ":"")
      +(hours>0 || days>0?hours+"h ":"")
      +(minutes>0 || hours>0?minutes+"m ":"")
      +(second>0 || minutes>0?second+"s ": ""))
  }

  const [countDown, setCountDown] = useState(0);
  useEffect(() => {
      // 创建定时器
      const interval = setInterval(() => {
          if (countDown>0) {
            setCountDown(countDown+1);
          }else{ 
            clearInterval(interval)
          }
      }, 1000);
      return () => clearInterval(interval);
  }, [countDown]);


  useEffect(() => {
    getInfo()
  }, []);

  return (
    <CommonPage tt={t('lbl_battlefield')}  flex padding0 title={t('lbl_battlefield')} 
    header={<CommonNavBar back={null} className="bgImg">{t('lbl_battlefield')}</CommonNavBar>}>
        {/*  switch  */}
        <section className={ styles.headerContent } ref={scrollRef}>
          <div className={ styles.headerBox } >
            { controlList.length>0&&controlList.map((item,index)=>
              <div className={ 
                classNames(
                  styles.headerItem, 
                  item.round < (userControl?.round+1)? language === "en_US"?styles.mask_En:styles.mask:"",// 已完成
                  index==controlIndex?styles.active:"",
                  )} 
              style={{ "backgroundImage": `url(${item.roundBg})` }}
              key={item.id}
              onClick={()=>navSelector(index)}>     
                {item.round == userControl?.round+1 && <img className={styles.now} src={loading}  />}
              </div>
            )}
          </div>
        </section>
        {/*  switch  */}
        <section className={styles.mainContent} style={{ "backgroundImage": `url(${controlList[controlIndex]?.roundBg})` }}>
          <div className={styles.mainGrid} >
            <div className={classNames(styles.topItem,styles.first)} >
              <div className='text-12 text-gradient line10'>{t("pool.level")}-{controlList[controlIndex]?.round||0}</div>
              <div className='text-16 text-gradient'>{controlList[controlIndex]?.roundName}</div>
            </div>
            <div className={styles.topItem}>
              <div className='text-12 text-aliceBlue line10'>{t("pool.settleContribution")}</div>
              <div className='text-16 text-bold'>{userControl?.earn||0}</div>
            </div>
            <div className={styles.topItem}>
              <div className='text-12 text-aliceBlue line10'>{t("pool.totalContribution")}</div>
              <div className='text-16 text-bold'>{nftInfo?.battlefieldEarns||0}</div>
            </div>
          </div>
          <div className={ styles.mainBox}>
            <audio ref={audioRef} id="noticeMusic"  loop="loop" preload="auto"  src={mp3} /> 
            <span className={styles.music} onClick={toggleAudio}>
              {playing?<IconOpen className={styles.icon}/>:<IconClose className={styles.icon}/>}
            </span>
          </div>
          <div className={styles.mainGrid} >
            <div className={classNames(styles.bottomItem, "basis-sm flex-shrink")} >
              <div className={ styles.icon }> 
                {propInfo?.image&& <img src={propInfo.image}/>}
              </div>
              <div>
                <div className='text-12 text-aliceBlue'>{t("pool.winRate")}</div>
                <div className='text-16 text-bold'>
                  {
                    // userControl?
                    // (controlList[userControl.round]?.roundRate||0): 
                  (controlList[controlIndex]?.roundRate||0) + "%"
                  } 
                  {propInfo?.XXXX && "+"+propInfo.XXXX +"%" } 
                </div>
              </div>
            </div>
            <div className={classNames(styles.bottomItem, styles.padding, "flex-sub text-bold")} >
              <div className={styles.icon } style={{"backgroundImage": `url(${nftInfo?.image})`}}> </div>
              <div className="flex-sub">
                <div className={classNames(styles.title,'text-12 text-sub')} >{nftInfo?.name||t("pool.none")}</div>
                <div className={styles.coin}><img src={gap}/><span>{nftInfo?.price||0}</span></div>
              </div>
            </div>
            <div className={classNames( styles.padding, "flex-shrink flex align-center")} >
              <button className={ classNames(styles.btn,styles.updata_btn)} 
                  onClick={()=>navigate("/shop")} 
                ><span> { nftInfo ? t("pool.buy"):t("pool.upgradation") }</span></button>
            </div>
          </div>
        </section>
        {/* 操作 */}
        <section className={styles.footerContent}>
          <div className='flex justify-between gap12'>
            <button className={styles.btn} onClick={() => navigate('histroy')}>
              <IconHistroy className={styles.icon} />
              <span>{t("pool.record")}</span>
            </button>
            <button className={styles.btn} onClick={() => navigate('rule')}>
              <IconRule className={styles.icon}/>
              <span>{t("pool.rule")}</span>
            </button>
            
            <button className={styles.btn} onClick={handleSoon} >
              <IconProp  className={styles.icon}/> 
              <span>{t("pool.prop")}</span>
            </button>
          </div>

          {userControl ?
            // 探索中
            <button className={ classNames(styles.apply_btn,styles.active)  } onClick={exit}>
              <IconExplore className={styles.icon}/>
              <span>{t("pool.exploration")}</span>
            </button>
            // 开始
            :<button className={styles.apply_btn} onClick={join}>
              <IconExplore className={styles.icon}/>
              <span>{t("pool.explore")}</span>
            </button>
          } 

          {userControl?.createTime  &&
            <div className='text-aliceBlue text-12 text-center' >
              {t("pool.exploredTime")}: {renderDifftime(countDown)} 
            </div>
          }
         
        </section>
        <div style={{ height: "4rem" }}></div>
      </CommonPage>
    );
  })