/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-06 14:53:47
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-19 10:22:46
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\components\icon\miningPool\IconProduce.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const IconHome = (className) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 36 36" fill="none">
  <g clip-path="url(#clip0_326_24000)">
    <g filter="url(#filter0_di_326_24000)">
      <path d="M13.3424 21.0693H6.73293C5.87001 21.0693 5.27475 20.1456 5.58182 19.2838L12.1494 0.8482C12.2383 0.598781 12.3959 0.384116 12.6014 0.232643C12.8068 0.0811712 13.0505 4.07339e-05 13.3001 0H24.3826C25.2571 0 25.8531 0.94742 25.5231 1.81362L21.699 11.8515H28.898C29.9567 11.8515 30.5216 13.1862 29.8233 14.037L12.1732 35.5449C11.3152 36.5907 9.72443 35.6845 10.0574 34.3398L13.3424 21.0693ZM14.1487 2.63416L8.51954 18.4356H14.9418C15.1304 18.4356 15.3164 18.4819 15.4856 18.571C15.6547 18.6601 15.8025 18.7895 15.9176 18.9492C16.0326 19.109 16.1118 19.2949 16.149 19.4926C16.1862 19.6903 16.1805 19.8945 16.1323 20.0894L13.7796 29.5952L26.1791 14.4852H19.8677C18.9933 14.4852 18.3972 13.5378 18.7272 12.6716L22.5513 2.63372H14.1487V2.63416Z" fill="url(#paint0_linear_326_24000)"/>
    </g>
  </g>
  <defs>
    <filter id="filter0_di_326_24000" x="4.5" y="0" width="26.6316" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="0.5"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_326_24000"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_326_24000" result="shape"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="-1"/>
      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.27 0"/>
      <feBlend mode="normal" in2="shape" result="effect2_innerShadow_326_24000"/>
    </filter>
    <linearGradient id="paint0_linear_326_24000" x1="17.8158" y1="0" x2="17.8158" y2="36" gradientUnits="userSpaceOnUse">
      <stop stop-color="white"/>
      <stop offset="1" stop-color="#C4D0FF"/>
    </linearGradient>
    <clipPath id="clip0_326_24000">
      <rect width="36" height="36" fill="white"/>
    </clipPath>
  </defs>
</svg>
    );
};

export default IconHome;
