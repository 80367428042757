import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import accountApi from "@/service/accountApi";


// 获取用户信息
const initUserInfo = createAsyncThunk(
    'user/initUserInfo',
    async () => {
        let userInfo =await accountApi.getUserInfo();
        // console.log(userInfo)
        return userInfo.data;
    })
const initUserAsset = createAsyncThunk(
    'user/initUserAsset',
    async () => {
        return [
            await accountApi.getUserAsset(),
            await accountApi.getUserAssetList()
        ];
    })
const initUserLocal = createAsyncThunk(
    'user/initUserLocal',
    async () => {
        return await accountApi.getUserlocal();
    })
// 退出登录
const userLogout = createAsyncThunk(
    'user/userLogout',
    async (_, {dispatch, getState}) => {
        console.log('user logout')
        let currentToken = getState().user.token;
        if (currentToken) {
            return await accountApi.logout()
                .then(() => {
                    dispatch(logout());
                })
                .catch((err) => {
                    dispatch(logout());
                });
        } else {
            return await Promise.resolve(1).then(() => {
                dispatch(logout());
            });
        }
    });

export const userSlice = createSlice({
    name: 'user',
    //初始值不能为null， 必须是二级
    initialState: {
        /*账户相关 */
        user: null,
        token: '',
        address: '',
        local:{},
        asset:[],
        /* 卡相关 */
        cardList: [],
        cardCurrentInfo: {},//注意。使用时不要使用es6解构
        cardIndex: 0,//注意。使用时不要使用es6解构
        addCardInfo:{}
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload
        },
        setAddress: (state, action) => {
            state.address = action.payload
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        logout: (state) => {
            state.user = null;
            state.token = '';
            state.address = '';
            state.cardList = [];
            state.cardCurrentInfo = {};
        },
        netLogout: (state) => {
            console.log('网络请求退出')
            state.token = '';
        },
        setCardCurrentInfo: (state, action) => {
            let payload = action.payload;
            state.cardCurrentInfo = payload;
        },
        setCardsAllInfo: (state, action) => {
            state.cardList = action.payload;
        },
        setAddCardInfo: (state, action) => {
            state.addCardInfo = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(initUserInfo.fulfilled, (state, action) => {
                console.log('用户信息', action.payload)
                state.user = action.payload;
            })
            .addCase(initUserAsset.fulfilled, (state, action) => {
                console.log('用户资产', action.payload)
                state.asset = action.payload[0];
                state.assetList = action.payload[1];
            })
            .addCase(initUserLocal.fulfilled, (state, action) => {
                console.log('收貨地址', action.payload)
                state.local = action.payload||{};
            })
            .addCase(userLogout.fulfilled, (state, action) => {
                console.log('退出成功')
            });
    }
})

export const {
    setUser,
    setAddress,
    setToken,
    logout,
    setCardCurrentInfo,
    setCardsAllInfo,
    setAddCardInfo
} = userSlice.actions;

export {initUserInfo, initUserAsset,initUserLocal, userLogout}

export default userSlice.reducer